import React, { useState } from "react";

const ViewSort = ({ onSelectChange }) => {
  const [sortBy, setSortBy] = useState("lastcreated");
  return (
    <div className="flex items-center mt-2">
      Sort by :
      <div className="flex justify-between px-2 py-1 ml-2 bg-white border-2 rounded-md border-zinc-400 rounde-md">
        <select
          onChange={(e) => {
            setSortBy(e.target.value);
            onSelectChange(e.target.value);
          }}
          value={sortBy}
          className="flex-grow p-1 text-gray-600 outline-none w-52"
        >
          <option value="lastcreated">Last Created</option>
          <option value="asc">Ascending Order</option>
          <option value="desc">Descending Order</option>
        </select>
      </div>
    </div>
  );
};

export default ViewSort;
