import React from "react";
import "tw-elements";

export default function dataModels() {
  return (
    <div className="virtualdatasets p-2">
      <div className="flex">
        <div className="flex items-center">
          <a className="text-slate-900 font-semibold font-GreycliffCF" href="">
            Data
          </a>
          <div className="mx-2 text-textgray-theme"> &#10095; </div>
          <div className="font-semibold text-textgray2-theme">Models</div>
        </div>
        <div className="ml-auto">
          <button
            className="ml-auto background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 ease-linear transition-all duration-150 flex"
            type="button"
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex items-center">
          <h1 className="text-xl text-textblue-theme">Models</h1>
        </div>
        <div className="ml-auto">
          <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white w-52">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
            <select className="flex-grow outline-none text-gray-600 ml-1 appearance-none px-2">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            <img
              className="w-4 ml-1 cursor-pointer"
              src={process.env.PUBLIC_URL + "/images/close-gray.svg"}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="flex mt-2 items-center">
        Sort by :
        <div className="border-2 border-zinc-400 ml-2 py-1 px-2 flex justify-between rounde-md rounded-md bg-white">
          <select className="flex-grow outline-none text-gray-600 p-1 w-52">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </select>
        </div>
      </div>
      <div className="bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]">
        <div className="flex py-3 px-3 text-textblue uppercase font-GreycliffCF font-bold">
          <div className="w-1/3">name</div>
        </div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
            <div
              id="flush-headingOne"
              class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <div className="w-1/3">Pilote Engine</div>
            </div>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0 bg-gray2-theme">
                <div className="h-96 overflow-y-auto pr-1 secondary-blue-scroll">
                  <div className="flex gap-4">
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                  </div>
                  <div className="mt-3">
                    <table class="min-w-full border-separate border-spacing-0 text-left">
                      <tbody>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
            <div
              id="flush-headingTwo"
              class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <div className="w-1/3">Pilote Engine</div>
            </div>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0 bg-gray2-theme">
                <div className="h-96 overflow-y-auto pr-1 secondary-blue-scroll">
                  <div className="flex gap-4">
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                  </div>
                  <div className="mt-3">
                    <table class="min-w-full border-separate border-spacing-0 text-left">
                      <tbody>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
            <div
              id="flush-headingThree"
              class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <div className="w-1/3">Pilote Engine</div>
            </div>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0 bg-gray2-theme">
                <div className="h-96 overflow-y-auto pr-1 secondary-blue-scroll">
                  <div className="flex gap-4">
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                    <div className="bg-white w-52 p-2">
                      <div className="font-bold">Feature</div>
                      <a href="" className="font-semibold">
                        Aircraft_engine
                      </a>
                      <div className="font-bold">Type</div>
                      <a href="" className="font-semibold">
                        Type goes here
                      </a>
                    </div>
                  </div>
                  <div className="mt-3">
                    <table class="min-w-full border-separate border-spacing-0 text-left">
                      <tbody>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-lightblue-theme">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                        <tr>
                          <td colSpan={9} className="py-1"></td>
                        </tr>
                        <tr className="bg-white">
                          <td class="rounded-l-lg whitespace-nowrap p-2">
                            BF0056G879
                          </td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="p-2">30</td>
                          <td class="rounded-r-lg whitespace-nowrap p-2">30</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
