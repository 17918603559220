import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { default as Dashboard, default as HomePage } from "./components/Home";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";
import PageNotFound from "./components/PageNotFound";
import { default as ProjectOverview } from "./components/Projects";
import { OpenProject } from "./components/Projects/OpenProject";
import DashboardD from "./design/Dashboard";
import Project from "./design/project";
import ViewWordEmbeddings from "./components/Data/ViewWordEmbeddings";
import ViewDataModel from "./components/Data/ViewDataModel";
import Tabsdata from "./design/project/tabsdata";
import ErrorBoundary from "./services/ErrorBoundaries";
import VirtualDatasets from "./design/virtualDatasets";
import DataSources from "./design/dataSources";
import DataModels from "./design/dataModels";
import WordEmbeddings from "./design/wordEmbeddings";
import ViewVDataSets from "./components/Data/ViewVirtualDatasets";
import ViewDataSource from "./components/Data/ViewSource";

// This configuration use hybrid mode
// ServiceWorker are used if available (more secure) else tokens are given to the client
// You need to give inside your code the "access_token" when using fetch
const oidcConfiguration = {
  // TODO: abstract to a configurable environment -corey
  client_id: "boss-webapp",
  redirect_uri: window.location.origin + "/#/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/#/authentication/silent-callback",
  scope: "openid profile email", // offline_access scope allow your client to retrieve the refresh_token
  // TODO: abstract to a configurable environment -corey
  authority: "https://dev.askboss.ai/auth/realms/dev.askboss.ai",
  service_worker_relative_url: "/OidcServiceWorker.js",
  service_worker_only: true,
};

const Layout = ({ children }) => {
  return (
    <OidcSecure>
      <div className="w-full">
        <Navbar />
        <div className="w-full min-h-screen pt-12 mb-10 bg-body-theme font-GreycliffCF sm:mb-0">
          {children}
        </div>
        <Footer />
        {/* <MobileIconMenu /> */}
      </div>
    </OidcSecure>
  );
};

function App() {
  return (
    <OidcProvider configuration={oidcConfiguration}>
      <BrowserRouter>
        <div>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <ErrorBoundary>
                    <Dashboard />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              path="/projectoverview"
              element={
                <Layout>
                  <ErrorBoundary>
                    <ProjectOverview />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              path="/openProject"
              element={
                <Layout>
                  <ErrorBoundary>
                    <OpenProject />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              path="/data/viewDataModel"
              element={
                <Layout>
                  <ErrorBoundary>
                    <ViewDataModel />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              path="/data/viewWordEmbedding"
              element={
                <Layout>
                  <ErrorBoundary>
                    <ViewWordEmbeddings />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              exact
              path="/"
              element={
                <Layout>
                  <ErrorBoundary>
                    <HomePage />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              exact
              path="/data/virtualdatasets"
              element={
                <Layout>
                  <ErrorBoundary>
                    <ViewVDataSets />
                  </ErrorBoundary>
                </Layout>
              }
            />

            <Route
              exact
              path="/data/virtualdatasource"
              element={
                <Layout>
                  <ErrorBoundary>
                    <ViewDataSource />
                  </ErrorBoundary>
                </Layout>
              }
            />
            <Route
              exact
              path="/design/project"
              element={
                <Layout>
                  <Project />
                </Layout>
              }
            />

            <Route
              exact
              path="/design/dashboard"
              element={
                <Layout>
                  <DashboardD />
                </Layout>
              }
            />
            <Route
              exact
              path="/design/project/tabsdata"
              element={
                <Layout>
                  <Tabsdata />
                </Layout>
              }
            />
            <Route
              exact
              path="/design/virtualDatasets"
              element={
                <Layout>
                  <VirtualDatasets />
                </Layout>
              }
            />
            <Route
              exact
              path="/design/dataSources"
              element={
                <Layout>
                  <DataSources />
                </Layout>
              }
            />
            <Route
              exact
              path="/design/dataModels"
              element={
                <Layout>
                  <DataModels />
                </Layout>
              }
            />
            <Route
              exact
              path="/design/wordEmbeddings"
              element={
                <Layout>
                  <WordEmbeddings />
                </Layout>
              }
            />
            <Route
              path="/*"
              element={
                <Layout>
                  <PageNotFound />
                </Layout>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </OidcProvider>
  );
}

export default App;
