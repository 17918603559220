import React, { useEffect, useState } from "react";
import { TextField, FileUploadWrapper } from "reactjs-component-library";
import Loader from "react-js-loader";
import { useOidcUser } from "@axa-fr/react-oidc";
import { hasNull } from "../../helper";
import { pyIcon, imageError } from "../../asset/images";
import "./modelDetails.css";

const ModelDetails = ({
  ModelData,
  ProjectData,
  createProjectModel,
  projectAddRemoveArtifact,
  refreshPage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { oidcUser } = useOidcUser();
  const initalModalInfo = {
    author_name: oidcUser && oidcUser?.email.split("@")[0],
    model_file: {},
    model_framework: "",
    model_library: "",
    model_name: "",
    model_description: "",
    model_type: "",
  };
  const [createModalInfo, setCreateModalInfo] = useState(initalModalInfo);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const type_library = {
    text_classification: ["texsorflow", "pytorch", "xgboost", "sklearn"],
    tabular_classification: [
      "texsorflow",
      "pytorch",
      "xgboost",
      "sklearn",
      "federated",
    ],
    regression: ["texsorflow", "pytorch", "xgboost", "sklearn", "federated"],
    image_classification: ["texsorflow", "pytorch", "xgboost", "federated"],
  };

  useEffect(() => {
    const isEmptyOrNull = hasNull(createModalInfo);
    setIsUploadDisabled(isEmptyOrNull);
    if (!createModalInfo.author_name) {
      setCreateModalInfo({
        ...createModalInfo,
        author_name: oidcUser && oidcUser?.email.split("@")[0],
      });
    }
  }, [createModalInfo]);

  const onFileInfo = (info) => {
    const { file } = info;
    setCreateModalInfo({ ...createModalInfo, model_file: file });
  };

  const onClickUpload = async () => {
    setModalLoading(true);
    setIsRefreshDisabled(true);

    const { id } = ProjectData;
    const finalData = { ...createModalInfo };
    const res = await createProjectModel(finalData);

    const addedInfo = {
      artifact: "models",
      operation: "add",
      uid: id,
      value: Object.keys(res)[0].toString(),
    };

    await projectAddRemoveArtifact(addedInfo);
    setIsRefreshDisabled(false);
    setModalLoading(false);
    setShowModal(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setCreateModalInfo(initalModalInfo);
  };

  const inputStyle = {
    display: "block",
    width: "100%",
    padding: "0.5rem",
    marginTop: "0.25rem",
    borderWidth: "2px",
    borderRadius: "0.375rem",
    borderColor: "rgb(161 161 170)",
  };

  const isLoading =
    ModelData && ModelData.length && ModelData[0] === "isLoading";

  if (isLoading) {
    return (
      <Loader
        type="spinner-circle"
        bgColor={"gray"}
        // title={"spinner-circle"}
        // color="black"
        size={50}
      />
    );
  }

  return (
    <>
      {/* <div className="flex"> */}
      <div
        className="flex"
        role="button"
        onClick={() => refreshPage({ isModel: true })}
        disabled={isRefreshDisabled}
      >
        <button
          className={
            isRefreshDisabled
              ? "flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-zinc-400 text-zinc-400"
              : "flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
          }
          type="button"
        >
          {!isRefreshDisabled && (
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />
          )}
          refresh
        </button>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full border-separate border-spacing-0 text-left">
          <thead class="text-xs uppercase">
            <tr>
              <th scope="col" class="p-2">
                name
              </th>
              <th scope="col" class="p-2">
                type
              </th>
              <th scope="col" class="p-2">
                author
              </th>
              <th scope="col" class="p-2">
                Library
              </th>
              <th scope="col" class="p-2">
                framework
              </th>
            </tr>
          </thead>
          <tbody>
            {ModelData && ModelData.length > 0
              ? ModelData.map((item) => (
                  <>
                    <tr>
                      <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                        {item?.model_name}
                      </td>
                      <td class="border-y border-[#919195] p-2">
                        {item?.type}
                      </td>
                      <td class="border-y border-[#919195] p-2">
                        {item?.author_name}
                      </td>
                      <td class="border-y border-[#919195] p-2">
                        {item?.model_library}
                      </td>
                      <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                        {item?.model_framework}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} className="py-1"></td>
                    </tr>
                  </>
                ))
              : "No records found!"}
          </tbody>
        </table>
      </div>
      <div className="flex mt-3">
        <button
          className="inline-flex justify-center p-2 ml-auto mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
          type="button"
          onClick={() => setShowModal(true)}
        >
          UPLOAD NEW MODEL
        </button>
        {showModal ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto min-w-[75%] md:min-w-[50%] xl:min-w-[25%]">
                {/*content*/}
                <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between bg-brightblue-theme border-b border-solid border-slate-200 rounded-t-[20px] text-white">
                    <h3 className="py-3 pl-3 m-0 font-semibold text-white">
                      Create New Model
                    </h3>
                    {!modalLoading && (
                      <button
                        className="ml-auto mr-3 text-4xl text-white bg-transparent border-0 outline-none focus:outline-none"
                        onClick={() => onCloseModal()}
                      >
                        ×
                      </button>
                    )}
                  </div>
                  {/*body*/}
                  {!modalLoading && (
                    <div className="relative flex-auto p-5">
                      <FileUploadWrapper
                        name="avatar"
                        accept=".py"
                        inputFileTypes={["text/x-python"]}
                        errorMessage="Cannot upload file. File size is > 5MB."
                        fileLimit={30}
                        onFileInfo={onFileInfo}
                        fileIcon={
                          <img
                            src={pyIcon}
                            alt=""
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                        }
                        errorIcon={
                          <img
                            src={imageError}
                            alt=""
                            style={{ width: "1rem", height: "1rem" }}
                          />
                        }
                      >
                        <>
                          <label className="block font-medium uppercase">
                            <button
                              className="inline-flex justify-center p-2 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-non bg-[#00BFB2] active:bg-emerald-600 hover:shadow-lg"
                              type="button"
                            >
                              SELECT File
                            </button>
                          </label>
                          <label
                            htmlFor="first-name"
                            className="block font-medium"
                          >
                            <p className="text-[#919195] tracking-wider">
                              .py files only. Max file size 30MB.
                            </p>
                          </label>
                        </>
                      </FileUploadWrapper>

                      <label
                        htmlFor="model_name"
                        className="block mt-3 font-medium uppercase"
                      >
                        Model Name
                      </label>

                      <TextField
                        placeholder="Enter Model Name"
                        value={createModalInfo.model_name}
                        defaultValue={createModalInfo.model_name}
                        style={inputStyle}
                        onChangeValue={(value) =>
                          setCreateModalInfo({
                            ...createModalInfo,
                            model_name: value,
                          })
                        }
                      />
                      <label
                        htmlFor="desc"
                        className="block mt-3 font-medium uppercase"
                      >
                        description
                      </label>

                      <TextField
                        placeholder="Enter Description"
                        fieldKey="model_description"
                        value={createModalInfo.model_description}
                        defaultValue={createModalInfo.model_description}
                        style={inputStyle}
                        onChangeValue={(value) =>
                          setCreateModalInfo({
                            ...createModalInfo,
                            model_description: value,
                          })
                        }
                      />
                      <label
                        htmlFor="type"
                        className="block mt-3 font-medium uppercase"
                      >
                        type
                      </label>
                      <select
                        id="type"
                        onChange={(e) =>
                          setCreateModalInfo({
                            ...createModalInfo,
                            model_type: e.target.value,
                          })
                        }
                        className="block w-full p-2 mt-1 border-2 rounded-md border-zinc-400"
                      >
                        <option value="" hidden>
                          Select Type
                        </option>

                        {Object.keys(type_library).map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                      <label
                        htmlFor="library"
                        className="block mt-3 font-medium uppercase"
                      >
                        library
                      </label>
                      <select
                        id="library"
                        onChange={(e) =>
                          setCreateModalInfo({
                            ...createModalInfo,
                            model_library: e.target.value,
                          })
                        }
                        className="block w-full p-2 mt-1 border-2 rounded-md border-zinc-400"
                      >
                        <option value="" hidden>
                          Select Library
                        </option>
                        {createModalInfo.model_type &&
                          Object.keys(
                            type_library[createModalInfo.model_type]
                          ).map((item) => (
                            <option
                              value={
                                type_library[createModalInfo.model_type][item]
                              }
                            >
                              {type_library[createModalInfo.model_type][item]}
                            </option>
                          ))}
                      </select>
                      <label
                        htmlFor="framework"
                        className="block mt-3 font-medium uppercase"
                      >
                        framework
                      </label>
                      <select
                        id="framework"
                        onChange={(e) =>
                          setCreateModalInfo({
                            ...createModalInfo,
                            model_framework: e.target.value,
                          })
                        }
                        className="block w-full p-2 mt-1 border-2 rounded-md border-zinc-400"
                      >
                        <option value="" hidden>
                          Select Framework
                        </option>

                        <option value="simple">simple</option>
                        <option value="advanced">advanced</option>
                      </select>
                    </div>
                  )}
                  {modalLoading && (
                    <Loader
                      type="spinner-circle"
                      bgColor={"gray"}
                      // title={"spinner-circle"}
                      // color="black"
                      size={50}
                    />
                  )}
                  {/*footer*/}
                  {!modalLoading && (
                    <div className="flex items-center justify-end pb-3 pr-3">
                      <button
                        className="p-2 mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
                        type="button"
                        onClick={() => onCloseModal()}
                      >
                        Cancel
                      </button>
                      <button
                        className="inline-flex justify-center p-2 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                        type="button"
                        // onClick={() => setShowModal(false)}
                        onClick={() => onClickUpload()}
                        disabled={isUploadDisabled}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ModelDetails;
