import React, { useRef, useEffect, useState } from "react";
import Loader from "react-js-loader";

const WordEmbeddingsDetails = ({ WordEmbeddingData, refreshPage }) => {
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const isLoading =
    WordEmbeddingData &&
    WordEmbeddingData.length &&
    WordEmbeddingData[0] === "isLoading";

  if (isLoading) {
    return (
      <Loader
        type="spinner-circle"
        bgColor={"gray"}
        // title={"spinner-circle"}
        // color="black"
        size={50}
      />
    );
  }

  return (
    <>
      <div
        className="flex"
        role="button"
        onClick={() => refreshPage({ isWordEmbedding: true })}
        disabled={isRefreshDisabled}
      >
        <button
          className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
          type="button"
        >
          <img
            className="mr-1"
            src={process.env.PUBLIC_URL + "/images/refresh.svg"}
            alt=""
          />{" "}
          refresh
        </button>
      </div>
      <div>
        <table className="min-w-full border-separate border-spacing-0 text-left">
          <thead className="text-xs uppercase">
            <tr>
              <th scope="col" className="p-2">
                Name
              </th>
              <th scope="col" className="p-2">
                Description
              </th>
              <th scope="col" className="p-2">
                Type
              </th>
            </tr>
          </thead>
          <tbody>
            {WordEmbeddingData.length > 0
              ? WordEmbeddingData.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                          {item.name}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {item.description}
                        </td>
                        <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2 text-left">
                          {item.type}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="py-1"></td>
                      </tr>
                    </>
                  );
                })
              : "No records found!"}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WordEmbeddingsDetails;
