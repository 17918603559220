import {
  DATA_VDS_LIST_INIT,
  DATA_VDS_LIST_SUCCESS,
  DATA_VDS_LIST_ERROR,
  DATA_SOURCE_LIST_INIT,
  DATA_SOURCE_LIST_SUCCESS,
  DATA_SOURCE_LIST_ERROR,
} from "./../actionTypes";
import { api } from "../../services/common";

export const fetchDataViewVDSList = async (dispatch) => {
  dispatch({ type: DATA_VDS_LIST_INIT });

  await api("/lucd/vds/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: DATA_VDS_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: DATA_VDS_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: DATA_VDS_LIST_ERROR, payload: err }));
};

export const fetchDataViewSourceList = async (dispatch) => {
  dispatch({ type: DATA_SOURCE_LIST_INIT });

  await api("/lucd/uds/sources", {
    method: "POST",
    body: {},
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: DATA_SOURCE_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: DATA_SOURCE_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: DATA_SOURCE_LIST_ERROR, payload: err }));
};

export const deleteVirtualDataset = async (dispatch, props) => {
  try {
    const { data } = await api("/lucd/vds/delete", {
      method: "POST",
      body: { ...props },
    });
    return data;
  } catch (err) {
    return err;
  }
};
