import React, { useEffect, useState } from "react";
import moment from "moment";
import { api } from "../../services/common";
import Loader from "react-js-loader";
import { sortDateModifiedDescending } from "../../helper";

const QueryDetails = ({ QueryData, refreshPage }) => {
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [QueryFederationData, setQueryFederationData] = useState(QueryData);
  useEffect(() => {
    calculateFederationValue();
  }, [QueryData]);

  const calculateFederationValue = async () => {
    await api("/lucd/federation/read", {
      method: "POST",
      body: { uid: null },
    })
      .then((res) => {
        let federationData = [];
        Object.keys(res.data).forEach((key) => {
          federationData.push({ id: key, ...res.data[key] });
        });
        let updatedQueryData = [];
        for (let index = 0; index < QueryData.length; index++) {
          const element = QueryData[index];
          let tempArr = [];
          Object.keys(element.federation).forEach((key) => {
            let val = federationData.filter((option) => option.id === key);
            tempArr.push(val[0]);
          });
          element.federationValue = tempArr[0].domain;
          updatedQueryData.push(element);
        }
        let sortedtmpData = sortDateModifiedDescending(updatedQueryData);
        setQueryFederationData(sortedtmpData);
      })
      .catch((err) => console.error(err));
  };

  const isLoading =
    QueryData && QueryData.length && QueryData[0] === "isLoading";

  if (isLoading) {
    return (
      <Loader
        type="spinner-circle"
        bgColor={"gray"}
        // title={"spinner-circle"}
        // color="black"
        size={50}
      />
    );
  }

  return (
    <>
      <div
        className="flex"
        role="button"
        onClick={() => refreshPage({ isQuery: true })}
        disabled={isRefreshDisabled}
      >
        <button
          className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
          type="button"
        >
          <img
            className="mr-1"
            src={process.env.PUBLIC_URL + "/images/refresh.svg"}
            alt=""
          />{" "}
          refresh
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-separate border-spacing-0 text-left">
          <thead className="text-xs uppercase">
            <tr>
              <th scope="col" className="p-2">
                Name
              </th>
              <th scope="col" className="p-2">
                Last modified
              </th>
              <th scope="col" className="p-2">
                Description
              </th>
              <th scope="col" className="p-2">
                Federation
              </th>
              <th scope="col" className="p-2">
                Virtual datasets
              </th>
              <th scope="col" className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {QueryFederationData.length > 0
              ? QueryFederationData.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                          {item?.name}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {moment(item?.modifiedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {item?.description}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {item?.federationValue ? item?.federationValue : ""}
                        </td>
                        <td className="border-y border-[#919195] whitespace-nowrap p-2">
                          {item?.vdses !== undefined
                            ? item?.vdses?.length
                            : "0"}
                        </td>
                        <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                          <button
                            className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                            type="button"
                          >
                            Transform
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={9} className="py-1"></td>
                      </tr>
                    </>
                  );
                })
              : "No records found!"}
          </tbody>
        </table>
      </div>
      <div className="flex mt-3">
        <button
          className="ml-auto inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          CREATE NEW QUERY
        </button>
      </div>
    </>
  );
};

export default QueryDetails;
