import React, { useState, useEffect } from "react";
import {
  fetchFederatedPercentage,
  VerticalProgressBar,
} from "reactjs-component-library";
import VdsesDetails from "./vdsesDetails";
import QueryDetails from "./queryDetails";
import ModelDetails from "./modelDetails";
import WordEmbeddingsDetails from "./wordEmbeddingsDetails";
// import VirtualDatasetsData from "./../../MockAPIs/VirtualDatasetsData.json";
// import QueryData from "./../../MockAPIs/QueryData.json";
import ModelTrainingsDetails from "./modelTrainingsDetails";

const ProjectDetailsTab = ({
  ProjectData,
  VdsesData,
  ModelData,
  SearchQueryData,
  WordEmbeddingData,
  ModelTrainingData,
  createProjectModel,
  projectAddRemoveArtifact,
  refreshPage,
}) => {
  const [openTab, setOpenTab] = useState("workflows");
  const [federatedPer, setFederatedPer] = useState(0);

  const calculateList = (label) => {
    switch (label) {
      case "models":
        return ModelData;
      case "virtual datasets":
        return VdsesData;
      case "queries":
        return SearchQueryData;
      case "nlp data":
        return WordEmbeddingData;
      case "model trainings":
        return ModelTrainingData;
      default:
        return VdsesData;
    }
  };

  useEffect(() => {
    if (openTab === "models") {
      setFederatedPer(fetchFederatedPercentage(calculateList("models")));
    }
    if (openTab === "virtual datasets") {
      setFederatedPer(
        fetchFederatedPercentage(calculateList("virtual datasets"))
      );
    }
    if (openTab === "workflows") {
      setFederatedPer(0);
    }
    if (openTab === "queries") {
      setFederatedPer(fetchFederatedPercentage(calculateList("queries")));
    }
    if (openTab === "model trainings") {
      setFederatedPer(
        fetchFederatedPercentage(calculateList("model trainings"))
      );
    }
    if (openTab === "nlp data") {
      setFederatedPer(fetchFederatedPercentage(calculateList("nlp data")));
    }
  }, [openTab]);

  const workflows = () => {
    return (
      <>
        <img
          className=""
          src={process.env.PUBLIC_URL + "/images/workflows.png"}
          alt=""
        />
        <div className="flex">
          <button
            className="inline-flex justify-center p-2 ml-auto mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
            type="button"
          >
            CREATE NEW WORKFLOW
          </button>
        </div>
      </>
    );
  };

  const data = [
    {
      label: "workflows",
      // link: "#",
      value: "54",
      desc: workflows(),
    },
    {
      label: "queries",
      // link: "#",
      value: SearchQueryData
        ? SearchQueryData.length && SearchQueryData.includes("isLoading")
          ? 0
          : SearchQueryData.length
        : "0",
      desc: (
        <QueryDetails QueryData={SearchQueryData} refreshPage={refreshPage} />
      ),
    },

    {
      label: "virtual datasets",
      // link: "#",
      value: VdsesData
        ? VdsesData.length && VdsesData.includes("isLoading")
          ? 0
          : VdsesData.length
        : "0",
      desc: (
        <VdsesDetails
          VirtualDatasetsData={VdsesData}
          refreshPage={refreshPage}
        />
      ),
    },

    {
      label: "models",
      // link: "#",
      value: ModelData
        ? ModelData.length && ModelData.includes("isLoading")
          ? 0
          : ModelData.length
        : 0,
      desc: (
        <ModelDetails
          ModelData={ModelData}
          ProjectData={ProjectData}
          createProjectModel={createProjectModel}
          projectAddRemoveArtifact={projectAddRemoveArtifact}
          refreshPage={refreshPage}
        />
      ),
    },

    {
      label: "nlp data",
      // link: "#",
      value: WordEmbeddingData
        ? WordEmbeddingData.length && WordEmbeddingData.includes("isLoading")
          ? 0
          : WordEmbeddingData.length
        : 0,
      desc: (
        <WordEmbeddingsDetails
          WordEmbeddingData={WordEmbeddingData}
          refreshPage={refreshPage}
        />
      ),
    },

    {
      label: "model trainings",
      // link: "#",
      value: ModelTrainingData
        ? ModelTrainingData.length && ModelTrainingData.includes("isLoading")
          ? 0
          : ModelTrainingData.length
        : 0,
      desc: (
        <ModelTrainingsDetails
          ModelTrainingData={ModelTrainingData}
          refreshPage={refreshPage}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col mt-5 rounded shadow">
      <ul className="flex m-0 overflow-hidden border rounded-t-lg border-blue-theme">
        {data.map(({ label, link, value }) => (
          <li
            key={label}
            className={`border-l border-blue-theme first:border-0 first:w-52 first:grow-0 grow text-center uppercase ${
              label === openTab ? "bg-blue-theme" : " bg-gray-theme"
            } hover:bg-brightblue-theme`}
          >
            <a
              href={link}
              onClick={() => setOpenTab(label)}
              className={`p-4 block text-center ${
                label === openTab ? "text-white" : " text-blue-theme"
              } hover:text-white flex justify-center flex-col h-full`}
            >
              <div className="text-base">{label}</div>
              <div className="text-4xl">{value}</div>
            </a>
          </li>
        ))}
      </ul>
      <div className="flex">
        <div className="bg-gray-theme px-4 pb-4 pt-12 w-52 md:min-w-[13rem] flex justify-center relative">
          <div className="absolute -mt-8 text-center uppercase text-secondblue-theme">
            Federated
          </div>

          <VerticalProgressBar
            value={federatedPer}
            id={`v_federated_${openTab}`}
          />
        </div>
        {data.map(({ label, desc }) => (
          <div
            key={label}
            className={`bg-white p-4 grow ${
              label === openTab ? "block" : "hidden"
            }`}
          >
            <h2 className="text-lg uppercase">{label}</h2>
            {desc}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetailsTab;
