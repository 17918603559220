//Login
export const STORE_LOGIN_DATA = "STORE_LOGIN_DATA";
export const REMOVE_LOGIN_DATA = "REMOVE_LOGIN_DATA";
export const UPDATE_LOGIN_DATA = "UPDATE_LOGIN_DATA";
export const UPDATE_LAST_ACTIVE = "UPDATE_LAST_ACTIVE";

//Project
export const STORE_PROJECT = "STORE_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const PROJECTS_READ = "PROJECTS_READ";

//ProjectList
export const PROJECT_LIST_INIT = "PROJECT_LIST_INIT";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_ERROR = "PROJECT_LIST_ERROR";

//VDSList
export const VDS_LIST_INIT = "VDS_LIST_INIT";
export const VDS_LIST_SUCCESS = "VDS_LIST_SUCCESS";
export const VDS_LIST_ERROR = "VDS_LIST_ERROR";

//TrainList
export const TRAIN_LIST_INIT = "TRAIN_LIST_INIT";
export const TRAIN_LIST_SUCCESS = "TRAIN_LIST_SUCCESS";
export const TRAIN_LIST_ERROR = "TRAIN_LIST_ERROR";

//ModelList
export const MODEL_LIST_INIT = "MODEL_LIST_INIT";
export const MODEL_LIST_SUCCESS = "MODEL_LIST_SUCCESS";
export const MODEL_LIST_ERROR = "MODEL_LIST_ERROR";

//SourceList
export const SOURCE_LIST_INIT = "SOURCE_LIST_INIT";
export const SOURCE_LIST_SUCCESS = "SOURCE_LIST_SUCCESS";
export const SOURCE_LIST_ERROR = "SOURCE_LIST_ERROR";

//WordEmbeddingsList
export const WE_LIST_INIT = "WE_LIST_INIT";
export const WE_LIST_SUCCESS = "WE_LIST_SUCCESS";
export const WE_LIST_ERROR = "WE_LIST_ERROR";

//SearchList
export const SEARCH_LIST_INIT = "SEARCH_LIST_INIT";
export const SEARCH_LIST_SUCCESS = "SEARCH_LIST_SUCCESS";
export const SEARCH_LIST_ERROR = "SEARCH_LIST_ERROR";

//Overview List
export const PROJECTOVERVIEW_PROJECT_LIST_INIT = "PROJECT_LIST_INIT";
export const PROJECTOVERVIEW_PROJECT_LIST_SUCCESS =
  "PROJECTOVERVIEW_PROJECT_LIST_SUCCESS";
export const PROJECTOVERVIEW_PROJECT_LIST_ERROR =
  "PROJECTOVERVIEW_PROJECT_LIST_ERROR";

//Data View
export const DATA_SOURCE_LIST_INIT = "DATA_SOURCE_LIST_INIT";
export const DATA_SOURCE_LIST_SUCCESS = "DATA_SOURCE_LIST_SUCCESS";
export const DATA_SOURCE_LIST_ERROR = "DATA_SOURCE_LIST_ERROR";
export const DATA_VDS_LIST_INIT = "DATA_VDS_LIST_INIT";
export const DATA_VDS_LIST_SUCCESS = "DATA_VDS_LIST_SUCCESS";
export const DATA_VDS_LIST_ERROR = "DATA_VDS_LIST_ERROR";
