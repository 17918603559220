import React from "react";
import { AutoCompleteSearch } from "reactjs-component-library";

const ViewSearch = (props) => {
  const {
    searchList,
    onSelectSearch,
    closeSearch,
    setCloseSearch,
    onClickCloseSearch,
  } = props;
  return (
    <div className="flex justify-between px-2 py-1 bg-white border-2 rounded-md border-zinc-400 rounde-md w-52">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="relative w-6 h-6 text-gray-400 transition duration-100 cursor-pointer hover:text-blue-400 top-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </span>
      <AutoCompleteSearch
        // className="flex-grow ml-1 text-gray-600 outline-none focus:text-blue-600"
        placeholder="Search"
        searchList={searchList}
        onSelectSearch={onSelectSearch}
        closeSearch={closeSearch}
        setCloseSearch={setCloseSearch}
        style={{ width: "150px" }}
      />
      <button onClick={onClickCloseSearch}>
        <img
          className="w-4 ml-1 cursor-pointer"
          src={process.env.PUBLIC_URL + "/images/close-gray.svg"}
          alt=""
          // role="button"
        />
      </button>
    </div>
  );
};

export default ViewSearch;
