import {
  PROJECT_LIST_INIT,
  // PROJECT_LIST_SUCCESS,
  // PROJECT_LIST_ERROR,
  // VDS_LIST_INIT,
  // VDS_LIST_SUCCESS,
  // VDS_LIST_ERROR,
  // TRAIN_LIST_INIT,
  // TRAIN_LIST_SUCCESS,
  // TRAIN_LIST_ERROR,
  // MODEL_LIST_INIT,
  // MODEL_LIST_SUCCESS,
  // MODEL_LIST_ERROR,
} from "./../actionTypes";
// import { api } from "../../services/common";
import {
  fetchModelList,
  fetchProjectList,
  fetchTrainList,
  fetchVDSList,
  fetchSourceList,
  fetchWordEmbeddings,
  fetchSearchList,
} from "./ListAction";
import { fetchProjectOverviewProjectList } from "./ProjectAction";

export const FetchHomePageHeaderInfo = async (dispatch) => {
  dispatch({ type: PROJECT_LIST_INIT });

  // await fetchModelList(dispatch);
  // await fetchVDSList(dispatch);
  // await fetchProjectList(dispatch);
  // await fetchSourceList(dispatch);
  // await fetchTrainList(dispatch);
  // await fetchWordEmbeddings(dispatch);
  // await fetchSearchList(dispatch);
  fetchModelList(dispatch);
  fetchVDSList(dispatch);
  fetchProjectList(dispatch);
  fetchSourceList(dispatch);
  fetchTrainList(dispatch);
  fetchWordEmbeddings(dispatch);
  fetchSearchList(dispatch);
};

export const fetchModelsRefresh = async (dispatch) => {
  await fetchModelList(dispatch);
  await fetchProjectOverviewProjectList(dispatch);
};

export const fetchVdsesRefresh = async (dispatch) => {
  await fetchVDSList(dispatch);
};

export const fetchQueryRefresh = async (dispatch) => {
  await fetchSearchList(dispatch);
};

export const fetchWordEmbeddingsRefresh = async (dispatch) => {
  await fetchWordEmbeddings(dispatch);
};

export const fetchModelTrainingRefresh = async (dispatch) => {
  await fetchTrainList(dispatch);
};
