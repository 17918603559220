import React from "react";
import { useState } from "react";

export default function Tabsdata() {
  const workflows = () => {
    return (
      <>
        <img
          className=""
          src={process.env.PUBLIC_URL + "/images/workflows.png"}
          alt=""
        />
        <div className="flex">
          <button
            className="ml-auto inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            CREATE NEW WORKFLOW
          </button>
        </div>
      </>
    );
  };
  const queries = () => {
    return (
      <>
        <div className="flex">
          <button
            className="ml-auto background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 mr-1 ease-linear transition-all duration-150 flex"
            type="button"
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0 text-left">
            <thead className="text-xs uppercase">
              <tr>
                <th scope="col" className="p-2">
                  name
                </th>
                <th scope="col" className="p-2">
                  Last modified
                </th>
                <th scope="col" className="p-2">
                  description
                </th>
                <th scope="col" className="p-2">
                  Federation
                </th>
                <th scope="col" className="p-2">
                  virtual datasets
                </th>
                <th scope="col" className="p-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td className="border-y border-[#919195] p-2">11/12/2022</td>
                <td className="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td className="border-y border-[#919195] p-2">
                  prod.askboss.ai
                </td>
                <td className="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-3">
          <button
            className="ml-auto inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            CREATE NEW QUERY
          </button>
        </div>
      </>
    );
  };
  const data = [
    {
      label: "workflows",
      link: "#",
      value: "54",
      desc: workflows(),
    },
    {
      label: "queries",
      link: "#",
      value: "54",
      desc: queries(),
    },

    {
      label: "virtual datasets",
      link: "#",
      value: "8",
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
    },

    {
      label: "models",
      link: "#",
      value: "8",
      desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
    },

    {
      label: "nlp data",
      link: "#",
      value: "23",
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
    },

    {
      label: "model trainings",
      link: "#",
      value: "5",
      desc: `Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.`,
    },
  ];
  const [openTab, setOpenTab] = useState("workflows");
  return (
    <div className="flex flex-col shadow rounded">
      <ul className="flex m-0 border border-blue-theme rounded-t-lg overflow-hidden">
        {data.map(({ label, link, value }) => (
          <li
            key={label}
            className={`border-l border-blue-theme first:border-0 first:w-52 first:grow-0 grow text-center uppercase ${
              label === openTab ? "bg-blue-theme" : " bg-gray-theme"
            } hover:bg-brightblue-theme`}
          >
            <a
              href={link}
              onClick={() => setOpenTab(label)}
              className={`p-4 block text-center ${
                label === openTab ? "text-white" : " text-blue-theme"
              } hover:text-white flex justify-center flex-col h-full`}
            >
              <div className="text-base">{label}</div>
              <div className="text-4xl">{value}</div>
            </a>
          </li>
        ))}
      </ul>
      <div className="flex">
        <div className="bg-gray-theme px-4 pb-4 pt-12 w-52 md:min-w-[13rem] flex justify-center relative">
          <div className="text-secondblue-theme uppercase absolute text-center -mt-8">
            Federated
          </div>
          <div className="flex flex-col mr-5 -ml-12">
            <div className="text-green-theme mb-auto font-semibold">80%</div>
            <div className="text-red2-theme mt-auto font-semibold">20%</div>
          </div>
          <div className="h-full bg-red2-theme w-9">
            <div className="bg-green-theme w-9" style={{ height: "80%" }}></div>
          </div>
        </div>
        {data.map(({ label, desc }) => (
          <div
            key={label}
            className={`bg-white p-4 grow ${
              label === openTab ? "block" : "hidden"
            }`}
          >
            <h2 className="text-lg uppercase">{label}</h2>
            {desc}
          </div>
        ))}
      </div>
    </div>
  );
}
