import React, { useState, useContext } from "react";
import moment from "moment";
import { ListWrapperContext } from "reactjs-component-library";

const VirtualDatasetRow = (props) => {
  const { item, index, deleteVirtualDataset, onDeleteSuccess } = props;
  const {
    getIsClicked,
    setIsClicked,
    setDelTriggered,
    delTriggered,
    onRowClick,
  } = useContext(ListWrapperContext);
  const [copiedIDToClipboard, setCopiedIDToClipboard] = useState(false);

  const copyProjectID = async () => {
    navigator.clipboard
      .writeText(item?.id)
      .then(() => {
        setCopiedIDToClipboard(true);
        setTimeout(() => {
          setCopiedIDToClipboard(false);
        }, 3000);
      })
      .catch(() => {
        alert("something went wrong");
      });

    // const res = await navigator.clipboard.readText();
    // console.log("res", res);
  };

  const closeDeleteModal = () => {
    setIsClicked(true);
    setDelTriggered(false);
  };

  const delVirtualDataset = async () => {
    const info = { uid: item.id };
    await deleteVirtualDataset({ ...info });
    onDeleteSuccess();
  };

  return (
    <div className="mb-2 overflow-hidden bg-white rounded-lg accordion-item">
      <div
        id={`flush-headingTwo-${index}`}
        className="relative flex items-center w-full p-3 border cursor-pointer accordion-button border-tablestroke collapsed"
        type="button"
        onClick={() => onRowClick()}
        data-bs-toggle="collapse"
        data-bs-target={`#flush-collapseTwo-${index}`}
        aria-expanded="false"
        aria-controls={`flush-collapseTwo-${index}`}
      >
        <div className="w-1/3">{item?.searchName}</div>
        <div className="w-1/3">{item?.description}</div>
        <div className="w-1/3">
          {item?.operations
            ? item?.operations?.length > 0
              ? item?.operations?.length
              : 0
            : ""}
        </div>
      </div>
      <div
        id={`flush-collapseTwo-${index}`}
        className="accordion-collapse collapse"
        aria-labelledby={`flush-headingTwo-${index}`}
        data-bs-parent="#accordionFlushExample"
      >
        <div className="p-4 overflow-hidden border border-t-0 rounded-lg accordion-body border-1 border-tablestroke">
          <div className="flex items-center">
            <div>
              <div className="text-sm font-bold">Last Modified</div>
              {/* <div className="text-base">11/22/2022 - 4:05 PM</div> */}
              <div className="text-base">
                {moment(item.modifiedAt).format("M/D/YYYY")}{" "}
                {moment(item.modifiedAt).format("LT")}
              </div>
            </div>
            <div className="flex mt-5 ml-auto">
              <a href="javascript:void(0)" className="relative ml-2 group">
                <span className="absolute px-2 py-1 text-white transition -translate-x-1/2 rounded opacity-0 pointer-events-none -top-8 left-1/2 whitespace-nowrap bg-blue-theme group-hover:opacity-100">
                  Open data transformation
                  <svg
                    class="absolute text-blue-theme h-2 w-full left-0 top-full"
                    x="0px"
                    y="0px"
                    viewBox="0 0 255 255"
                  >
                    <polygon
                      class="fill-current"
                      points="0,0 127.5,127.5 255,0"
                    />
                  </svg>
                </span>
                <img
                  src={process.env.PUBLIC_URL + "/images/external.svg"}
                  alt=""
                  className="h-6"
                />
              </a>
              <a
                href={"javascript:void(0)"}
                className="relative ml-2 group"
                role="button"
                onClick={() => copyProjectID()}
                id="clipboarddatasetID"
              >
                <span className="absolute right-0 px-2 py-1 text-white transition rounded opacity-0 pointer-events-none -top-8 whitespace-nowrap bg-blue-theme group-hover:opacity-100">
                  Copy ID to Clipboard
                  <svg
                    class="absolute text-blue-theme h-2 w-5 right-0 top-full"
                    x="0px"
                    y="0px"
                    viewBox="0 0 255 255"
                  >
                    <polygon
                      class="fill-current"
                      points="0,0 127.5,127.5 255,0"
                    />
                  </svg>
                </span>
                <img
                  src={process.env.PUBLIC_URL + "/images/copy.svg"}
                  alt=""
                  className="h-6"
                />
                {copiedIDToClipboard && (
                  <div
                    id={`${item?.id}-copyId`}
                    className="absolute z-0 p-3 mt-0 align-middle transition duration-150 ease-in-out bg-white rounded shadow-lg min-w-[180px] right-10"
                  >
                    <span className="flex justify-center align-middle">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/check-green-1.svg"
                        }
                        alt=""
                        className="mr-2"
                      />
                      <span className="text-textblue-theme">
                        Project ID copied
                      </span>
                    </span>
                  </div>
                )}
              </a>

              <div
                // href="javascript:void(0)"
                id={item?.id}
                role="button"
                onClick={() => {
                  if (!getIsClicked()) {
                    // console.log("button clicked");
                    setDelTriggered(true);
                  }
                }}
                className="relative ml-2 group"
              >
                <span className="absolute right-0 px-2 py-1 text-white transition rounded opacity-0 pointer-events-none -top-8 whitespace-nowrap bg-blue-theme group-hover:opacity-100">
                  Delete
                  <svg
                    class="absolute text-blue-theme h-2 w-5 right-0 top-full"
                    x="0px"
                    y="0px"
                    viewBox="0 0 255 255"
                  >
                    <polygon
                      class="fill-current"
                      points="0,0 127.5,127.5 255,0"
                    />
                  </svg>
                </span>
                <img
                  src={process.env.PUBLIC_URL + "/images/trash.svg"}
                  alt=""
                  className="h-6"
                />
                {delTriggered === true && (
                  <div
                    id={`${item?.id}-delcon`}
                    className="absolute z-0 p-3 mt-0 transition duration-150 ease-in-out bg-white rounded shadow-lg w-80 right-16"
                  >
                    <p className="flex justify-center pb-1 mt-6 text-sm font-bold leading-5 text-gray-800">
                      Are you sure you want to <br />
                      delete this virtual dataset?
                    </p>

                    <div className="flex justify-center mt-4 mb-5">
                      <div className="flex items-center">
                        <button
                          className="p-2 mr-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-slate-600 text-slate-600"
                          type="button"
                          onClick={() => {
                            closeDeleteModal();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="p-2 text-sm text-white uppercase transition-all duration-150 ease-linear border-2 rounded bg-pourpre-theme border-pourpre-theme"
                          type="button"
                          onClick={() => {
                            delVirtualDataset();
                            // setDeleteProject(false);
                            // DeleteProjectHandler();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="text-sm font-bold">Description</div>
            <div className="text-base">{item?.description || "NA"}</div>
          </div>
          <div className="flex flex-wrap mt-4">
            <div className="flex w-full lg:w-2/5 lg:border-r-2">
              <div className="w-1/2 pr-3 lg:pr-5">
                <div className="p-2 border rounded-lg bg-slate-100 boredr-1 border-slate-300">
                  <h4 className="text-sm font-bold">Feature columns</h4>
                  <div className="h-40 pr-1 overflow-y-auto secondary-blue-scroll">
                    {item?.data_model && item?.data_model?.data?.length > 0 ? (
                      item?.data_model?.data.map((dataItem) => (
                        <div className="mb-1">{dataItem}</div>
                      ))
                    ) : (
                      <> </>
                    )}
                    {/* <div className="mb-1">engine_prediction.age</div>
                    <div className="mb-1">engine_prediction.age</div>
                    <div className="mb-1">engine_prediction.age</div>
                    <div className="mb-1">engine_prediction.age</div>
                    <div className="mb-1">engine_prediction.age</div>
                    <div className="mb-1">engine_prediction.age</div> */}
                  </div>
                </div>
              </div>
              <div className="w-1/2 pr-3 lg:pr-5">
                <div className="p-2 border rounded-lg bg-slate-100 boredr-1 border-slate-300">
                  <h4 className="text-sm font-bold">Label columns</h4>
                  <div className="h-40 pr-1 overflow-y-auto secondary-blue-scroll">
                    {item?.data_model &&
                    item?.data_model?.labels?.length > 0 ? (
                      item?.data_model?.labels.map((labelItem) => (
                        <div className="mb-1">{labelItem}</div>
                      ))
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-3 lg:w-3/5 lg:mt-0">
              <div className="pl-3">
                <h4 className="text-sm font-bold">
                  Data Transformation Sequence
                </h4>
                {item && item?.operations && item?.operations?.length ? (
                  <>
                    {item?.operations.map((operationItem) => (
                      <div className="flex items-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/setting-yellow.svg"
                          }
                          alt=""
                          className="h-6"
                        />
                        <div className="ml-2 text-base">
                          {operationItem.command}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <> </>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <button
              className="p-2 mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
              type="button"
            >
              create embedding
            </button>
            <button
              className="inline-flex justify-center p-2 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
              type="button"
            >
              start training
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualDatasetRow;
