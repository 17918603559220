import react, {useState} from "react";
const Dashboard = () => {
const [showModal, setShowModal] = useState(false);

  return (
    <div className="p-5">
      <div className="flex flex-wrap">
        <div className="w-full md:w-full lg:w-3/12 xl:w-4/12 2xl:w-4/12">
          <div className="rounded-lg shadow-lg p-3 bg-white">
            <h2 className="text-xl border-b border-gray-400 text-textblue-theme pb-2 mb-1">Your Recent Activity</h2>
            <div className="h-48 md:h-52 xl:h-52 overflow-y-auto pr-1">          
              <p className="text-secondblue-theme uppercase">TODAY</p>
              <div className="bg-gray-theme p-2 text-textblue-theme h-min mb-1">
                <div className="flex items-start">
                  <img className="mr-1" src={process.env.PUBLIC_URL + "/images/round-plus.svg"} alt=""/>
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span> <span className="text-pourpre-theme font-semibold ml-1">Monterey Bay Course</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
              </div>
              <div className="bg-gray-theme p-2 text-textblue-theme h-min mb-1">
                <div className="flex items-start">
                  <img className="mr-1" src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt=""/>
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span> <span className="font-semibold ml-1">Select Military crashes...</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span> <span className="text-pourpre-theme font-semibold ml-1">Monterey Bay Course</span>
              </div>
              <div className="bg-gray-theme p-2 text-textblue-theme h-min mb-1">
                <div className="flex items-start">
                  <img className="mr-1" src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt=""/>
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span> <span className="font-semibold ml-1">Select Military crashes...</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span> <span className="text-pourpre-theme font-semibold ml-1">Monterey Bay Course</span>
              </div>
              <p className="text-secondblue-theme uppercase">March 31, 2022</p>
              <div className="bg-gray-theme p-2 text-textblue-theme h-min mb-1">
                <div className="flex items-start">
                  <img className="mr-1" src={process.env.PUBLIC_URL + "/images/round-plus.svg"} alt=""/>
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="text-pourpre-theme font-semibold">Monterey Bay Course</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-full lg:w-9/12 xl:w-8/12 2xl:w-8/12 pl-0 lg:pl-5 mt-4 lg:mt-0">
          <div className="flex flex-wrap gap-3">
            <div className="text-center flex-1">
              <div className="bg-gray2-theme rounded-t-lg mb-2 pt-1 pb-2 xl:py-3">
                <div className="text-lg 2xl:text-xl text-textblue-theme px-2 uppercase whitespace-nowrap">SOURCES</div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">29</div>
              </div>
              <div className="bg-white rounded-b-lg shadow-lg px-3 py-2 xl:p-3">
                <div className="text-secondblue-theme uppercase">Federated</div>
                <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div className="bg-green-theme h-2.5 rounded-full" style={{width: "80%"}}></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div>
              </div>
            </div>
            <div className="text-center flex-1">
              <div className="bg-gray2-theme rounded-t-lg mb-2 pt-1 pb-2 xl:py-3">
                <div className="text-lg 2xl:text-xl text-textblue-theme px-2 uppercase whitespace-nowrap">VIRTUAL DATASETS</div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">65</div>
              </div>
              <div className="bg-white rounded-b-lg shadow-lg px-3 py-2 xl:p-3">
                <div className="text-secondblue-theme uppercase">Federated</div>
                <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div className="bg-green-theme h-2.5 rounded-full" style={{width: "80%"}}></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div>
              </div>
            </div>
            <div className="text-center flex-1">
              <div className="bg-gray2-theme rounded-t-lg mb-2 pt-1 pb-2 xl:py-3">
                <div className="text-lg 2xl:text-xl text-textblue-theme px-2 uppercase whitespace-nowrap">MODELS</div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">12</div>
              </div>
              <div className="bg-white rounded-b-lg shadow-lg px-3 py-2 xl:p-3">
                <div className="text-secondblue-theme uppercase">Federated</div>
                <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div className="bg-green-theme h-2.5 rounded-full" style={{width: "80%"}}></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div>
              </div>
            </div>
            <div className="text-center flex-1">
              <div className="bg-gray2-theme rounded-t-lg mb-2 pt-1 pb-2 xl:py-3">
                <div className="text-lg 2xl:text-xl text-textblue-theme px-2 uppercase whitespace-nowrap">WORD Embeddings</div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">8</div>
              </div>
              <div className="bg-white rounded-b-lg shadow-lg px-3 py-2 xl:p-3">
                <div className="text-secondblue-theme uppercase">Federated</div>
                <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div className="bg-green-theme h-2.5 rounded-full" style={{width: "80%"}}></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div>
              </div>
            </div>
            <div className="text-center flex-1">
              <div className="bg-gray2-theme rounded-t-lg mb-2 pt-1 pb-2 xl:py-3">
                <div className="text-lg 2xl:text-xl text-textblue-theme px-2 uppercase whitespace-nowrap">MODEL TRAININGS</div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">8</div>
              </div>
              <div className="bg-white rounded-b-lg shadow-lg px-3 py-2 xl:p-3">
                <div className="text-secondblue-theme uppercase">Federated</div>
                <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div className="bg-green-theme h-2.5 rounded-full" style={{width: "80%"}}></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-lg my-3 font-medium">
        Projects:
        <span className="h-10 w-10 bg-gray2-theme p-1 ml-1 font-bold">45</span>        
      </div>
      <div className="flex flex-wrap mt-5 mb-2 gap-4">
        {/* <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </span>
          <input className="flex-grow outline-none text-gray-600 focus:text-blue-600 ml-1" type="text" placeholder="Search" />
        </div> */}
        <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white w-52">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </span>
          <select className="flex-grow outline-none text-gray-600 ml-1 appearance-none px-2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </select>
          <img className="w-4 ml-1 cursor-pointer" src={process.env.PUBLIC_URL + "/images/close-gray.svg"} alt=""/>
        </div>
        <div className="flex items-center">
          Sort by:
          <select className="border-2 border-zinc-400 p-2 bg-white ml-2">
            <option>Last Created</option>
          </select>
        </div>
        <div className="flex items-center ml-auto">
        <button type="submit" className="inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase" onClick={() => setShowModal(true)}><img className="mr-1 h-5" src={process.env.PUBLIC_URL + "/images/round-plus-white.svg"} alt=""/> new project</button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto min-w-[75%] md:min-w-[50%] xl:min-w-[25%]">
              {/*content*/}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between bg-brightblue-theme border-b border-solid border-slate-200 rounded-t-[20px] text-white">
                  <h3 className="font-semibold pl-3 py-3 m-0">Create New Project</h3>
                  <button className="mr-3 ml-auto bg-transparent border-0 text-white outline-none focus:outline-none text-4xl" onClick={() => setShowModal(false)}>×</button>
                </div>
                {/*body*/}
                <div className="relative p-5 flex-auto">
                  <label htmlFor="first-name" className="block font-medium uppercase">Project name</label>
                  <input type="text" name="first-name" id="first-name" autoComplete="given-name" placeholder="Enter Operation Name" className="mt-1 block w-full border-2 rounded-md border-zinc-400 p-2"/>
                  <label htmlFor="about" className="block font-medium uppercase mt-3">project description</label>
                    <textarea id="about" name="about" rows={3} className="mt-1 block w-full border-2 rounded-md border-zinc-400 p-2" placeholder="Enter Operation Name"/>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end pr-3 pb-3">
                  <button className="background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 mr-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>Cancel</button>
                  <button className="inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>Create</button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
        </div>
      </div>      
      <div className="flex flex-wrap mt-5 mb-3">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
          <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
            <div className="bg-white rounded-r-lg -mr-1 flex">
              <div className="w-1/3 rounded-r-lg">
                <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
              </div>
              <div className="w-2/3 px-2 py-3 flex flex-col">
                <h2 className="uppercase text-sm font-medium mb-2 group relative">
                  <span className="pointer-events-none absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-blue-theme px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/6 before:top-full before:-translate-x-1/6 before:border-4 before:border-transparent before:content-[''] group-hover:opacity-100">Aviation Testing Center USA</span>
                  Monterey Bay Course
                </h2>
                <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
                <div className="flex mt-auto uppercase text-secondblue">
                  <div className="flex items-start mr-3">
                    <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                    <div className="ml-2">
                      <h2 className="text-2xl -mt-1">5</h2>
                      <p className="text-xs">models</p>
                    </div>
                  </div>
                  <div className="ml-auto flex items-start">
                    <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                    <div className="ml-2">
                      <h2 className="text-2xl -mt-1">12</h2>
                      <p className="text-xs">VIRTual datasets</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img2.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img2.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img3.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img3.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img4.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img4.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img5.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img5.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img2.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img2.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img3.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img3.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img4.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img4.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img5.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5 p-2">
        <div className="bg-brightblue-theme rounded-lg pl-2 shadow-md h-48">
          <div className="bg-white rounded-r-lg -mr-1 flex">
            <div className="w-1/3 rounded-r-lg">              
              <img src={process.env.PUBLIC_URL + "/images/img5.png"} alt="" className="w-full aspect-[87/197] object-cover object-center h-48" />
            </div>
            <div className="w-2/3 px-2 py-3 flex flex-col">
              <h2 className="uppercase line-clamp-1 text-sm font-medium mb-2">Monterey Bay Course</h2>
              <p className="line-clamp-4 font-medium text-sm text-zinc-400">Monterey Bay Course text description goes here and here and here however long this is to here Monterey Bay Course textMonterey Bay Course text.</p>
              <div className="flex mt-auto uppercase text-secondblue">
                <div className="flex items-start mr-3">
                  <img src={process.env.PUBLIC_URL + "/images/models.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">5</h2>
                    <p className="text-xs">models</p>
                  </div>
                </div>
                <div className="ml-auto flex items-start">
                  <img src={process.env.PUBLIC_URL + "/images/virtual.png"} alt="" className="mt-1" />
                  <div className="ml-2">
                    <h2 className="text-2xl -mt-1">12</h2>
                    <p className="text-xs">VIRTual datasets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <button type="submit" className="inline-flex justify-center rounded border border-transparent bg-green-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase">load more</button>
    </div>
  );
};

export default Dashboard;