import {
  STORE_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  PROJECTS_READ,
  PROJECT_LIST_INIT,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_ERROR,
  VDS_LIST_INIT,
  VDS_LIST_SUCCESS,
  VDS_LIST_ERROR,
  PROJECTOVERVIEW_PROJECT_LIST_ERROR,
  PROJECTOVERVIEW_PROJECT_LIST_INIT,
  PROJECTOVERVIEW_PROJECT_LIST_SUCCESS,
} from "./../actionTypes";
import { FetchInstance } from "../../services";

import { api, fileUploadApi } from "../../services/common";

import project_read from "../../MockAPIs/projects_read.json";

const fetch = FetchInstance();
const BASE_URL = "https://dev.askboss.ai";

export const storeProjectAction = (payload) => {
  let data = payload;
  return {
    type: STORE_PROJECT,
    payload: data,
  };
};

export const createProjectAction = (payload) => {
  let data = payload;
  return {
    type: CREATE_PROJECT,
    payload: data,
  };
};

export const updateProjectAction = (payload) => {
  let data = payload;
  return {
    type: UPDATE_PROJECT,
    payload: data,
  };
};

export const deleteProjectAction = (payload) => {
  let data = payload;
  return {
    type: DELETE_PROJECT,
    payload: data,
  };
};

export const ReadProjectData = () => {
  const tmpArray = [];
  Object.keys(project_read).forEach((key) => {
    if (key !== "default") {
      tmpArray.push({ uid: key, ...project_read[key] });
    }
  });
  return {
    type: PROJECTS_READ,
    payload: tmpArray,
  };
};

export const CreateProjectData = (payload) => {
  const { projectsList, newProject } = payload;

  const tmpArray = [...projectsList];
  tmpArray.push({
    uid: `${newProject.name}${projectsList.length + 1}`,
    ...newProject,
  });

  return {
    type: PROJECTS_READ,
    payload: tmpArray,
  };
};

export const createProjectModel = async (dispatch, payload) => {
  try {
    const { data } = await fileUploadApi("/lucd/model/create", {
      method: "POST",
      body: { ...payload },
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const projectAddRemoveArtifact = async (dispatch, payload) => {
  let resultSet = { data: [] };
  if (fetch) {
    const fetchAPIAsync = async () => {
      const res = await fetch(`${BASE_URL}/lucd/project/add_remove`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (res.status >= 300) {
        return { data: [], statusCode: res.status };
      }
      return JSON.parse(JSON.stringify(res));
    };
    await fetchAPIAsync().then((apiData) => {
      const { data } = apiData;
      resultSet = {
        data: data,
      };
    });
  }
  return resultSet;
};

export const fetchProjectOverviewProjectList = async (dispatch) => {
  dispatch({ type: PROJECTOVERVIEW_PROJECT_LIST_INIT });

  await api("/lucd/project/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({
            type: PROJECTOVERVIEW_PROJECT_LIST_ERROR,
            payload: "error",
          });
        }
      } else {
        dispatch({
          type: PROJECTOVERVIEW_PROJECT_LIST_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: PROJECTOVERVIEW_PROJECT_LIST_ERROR, payload: err });
    });
};
