import React from "react";

const PageNotFound = (props) => {
  return (
    <div
      className={`content-container bg-gray-100 overflow-y-scroll scrollbar-hide ${
        props.Sidebar
          ? "w-full sm:content md:content lg:content xl:content"
          : "w-full sm:content-extra md:content-extra mg:content-extra xl:content-extra"
      }`}
    >
      <div className="flex flex-col items-center justify-center w-full h-full bg-gray-50">
        <h1 className="w-full mt-10 text-5xl font-bold text-center text-red-500 uppercase">
          Error
        </h1>
        <p className="w-full mt-5 font-black text-center text-gray-900 uppercase text-9xl">
          404
        </p>
        <p className="w-full mt-5 text-3xl font-bold text-center text-red-500 capitalize">
          page not found
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
