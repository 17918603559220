import {
  DATA_VDS_LIST_INIT,
  DATA_VDS_LIST_SUCCESS,
  DATA_VDS_LIST_ERROR,
  DATA_SOURCE_LIST_INIT,
  DATA_SOURCE_LIST_SUCCESS,
  DATA_SOURCE_LIST_ERROR,
} from "./../actionTypes";

export const DataView = (
  state = {
    isVDSDataLoading: null,
    vdsList: null,
    isVDSDataError: null,
    isSourceDataLoading: null,
    sourceList: null,
    isSourceDataError: null,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case DATA_VDS_LIST_INIT:
      return {
        ...state,
        isVDSDataLoading: true,
      };
    case DATA_VDS_LIST_SUCCESS:
      return {
        ...state,
        isVDSDataLoading: false,
        vdsList: payload,
        isVDSDataError: null,
      };
    case DATA_VDS_LIST_ERROR:
      return {
        ...state,
        isVDSDataLoading: false,
        vdsList: [],
        isVDSDataError: payload,
      };

    case DATA_SOURCE_LIST_INIT:
      return {
        ...state,
        isSourceDataLoading: true,
      };
    case DATA_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        isSourceDataLoading: false,
        sourceList: payload,
        isSourceDataError: null,
      };

    case DATA_SOURCE_LIST_ERROR:
      return {
        ...state,
        isSourceDataLoading: false,
        sourceList: [],
        isSourceDataError: payload,
      };

    default:
      return state;
  }
};
