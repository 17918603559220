import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { getStorage } from "reactjs-component-library";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import * as allReducers from "./reducers";

const rootReducer = combineReducers({
  ...allReducers,
});

const persistConfig = {
  key: "root",
  storage: getStorage(),
  whitelist: ["LoginReducer", "ListReducer", "ProjectReducer", "DataReducer"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
//   const middleware = applyMiddleware(thunk);
// const store = createStore(pReducer, middleware);

const store = createStore(
  pReducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);
export { persistor, store };
