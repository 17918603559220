import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import {
  AutoCompleteSearch,
  HorizontalProgressBar,
  fetchFederatedPercentage,
} from "reactjs-component-library";
import Loader from "react-js-loader";
import AddProject from "./AddProject";
import ProjectSort from "./ProjectSort";
import Project from "./Project";
import {
  sortDateDescending,
  SortAscendingOrder,
  SortDescendingOrder,
  readSearchList,
} from "../../helper";
import { useDispatch } from "react-redux";
import { storeProjectAction } from "./../../Redux/actions/ProjectAction";

const HomePage = (props) => {
  const {
    isProjectLoading: isLoading,
    completeProjectList,
    vdsList,
    modelList,
    trainList,
    sourceList,
    weList,
    FetchHomePageHeaderInfo,
  } = props;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [SortBy, setSortBy] = useState("lastcreated");

  const [arrayInfo, setArrayInfo] = useState({ arrayLength: 0, arrayData: [] });
  const [projectList, setProjectList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [closeSearch, setCloseSearch] = useState(false);
  const [completeProjectArray, setCompleteProjectArray] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const numberOfProjects = useRef();

  const fetchInfo = useCallback(() => {
    FetchHomePageHeaderInfo();
  }, []);

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    if (isLoading === false && vdsList && modelList) {
      setIsProjectLoading(false);
    }
    if (isLoading) {
      setIsProjectLoading(true);
    }
  }, [isLoading, vdsList, modelList]);

  const setListData = useCallback(
    (list) => {
      if (list.length) {
        const data = list;
        let tmpData;
        if (SortBy === "lastcreated") {
          tmpData = sortDateDescending(data);
        }
        if (SortBy === "desc") {
          tmpData = SortDescendingOrder(data);
        }
        if (SortBy === "asc") {
          tmpData = SortAscendingOrder(data);
        }

        let arrLen = 0;
        let listData = [];
        let arrData = [];
        let completeArr = [];
        if (data?.length) {
          numberOfProjects.current = data.length;
          arrLen = Math.ceil(data.length / 24);
          for (let i = 0; i < arrLen; i++) {
            if (i === 0) {
              listData = tmpData.splice(0, 24);
              completeArr = [...listData];
            } else {
              arrData[i - 1] = tmpData.splice(0, 24);
              completeArr = [...completeArr, ...arrData[i - 1]];
            }
          }
        }

        setCompleteProjectArray(completeArr);
        setProjectList(listData);
        setArrayInfo({ arrayLength: arrLen, arrayData: arrData });
      }
    },
    [completeProjectArray, SortBy]
  );

  useEffect(() => {
    (async () => {
      if (completeProjectList) {
        const { data } = await readSearchList(completeProjectList);
        setListData(data);
        let tmpArray = [];
        Object.keys(completeProjectList).forEach((key) => {
          tmpArray.push({ id: key, ...completeProjectList[key] });
        });
        dispatch(storeProjectAction(tmpArray));
      }
    })();
  }, [completeProjectList]);

  useEffect(() => {
    setListData(completeProjectArray);
  }, [SortBy]);

  useEffect(() => {
    if (closeSearch) {
      setSearchList([]);
    }
  }, [closeSearch]);

  const onLoadMore = () => {
    if (arrayInfo.arrayData.length) {
      const concatArray = arrayInfo.arrayData.splice(0, 1);
      const tmpArray = [...projectList, ...concatArray[0]];
      let sortedArray = [];
      if (SortBy === "lastcreated") {
        sortedArray = sortDateDescending(tmpArray);
      } else if (SortBy === "asc") {
        sortedArray = SortAscendingOrder(tmpArray);
      } else {
        sortedArray = SortDescendingOrder(tmpArray);
      }

      setProjectList([...sortedArray]);
    }
  };

  const onClickCloseSearch = () => {
    setCloseSearch(true);
  };

  const onSelectSearchItem = (item) => {
    if (_.isEqual(item.id, "no records found")) {
      const tmpObj = {
        searchName: "0 Result Found",
        description: "",
      };
      setSearchList([{ ...tmpObj }]);
    } else {
      setSearchList([item]);
    }
  };

  return (
    <div className="p-5 overflow-x-hidden">
      <div className="flex flex-wrap">
        <div className="w-full md:w-full lg:w-3/12 xl:w-4/12 2xl:w-4/12">
          <div className="p-3 bg-white rounded-lg shadow-lg">
            <h2 className="pb-2 mb-1 text-xl border-b border-gray-400 text-textblue-theme">
              Your Recent Activity
            </h2>
            <div className="h-48 pr-1 overflow-y-auto md:h-52 xl:h-52">
              <p className="uppercase text-secondblue-theme">TODAY</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>{" "}
                    <span className="ml-1 font-semibold text-pourpre-theme">
                      Monterey Bay Course
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <p className="uppercase text-secondblue-theme">March 31, 2022</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pl-0 mt-4 md:w-full lg:w-9/12 xl:w-8/12 2xl:w-8/12 lg:pl-5 lg:mt-0">
          <div className="flex flex-wrap gap-3">
            <div className="flex-1 text-center">
              <div className="pt-1 pb-2 mb-2 rounded-t-lg bg-gray2-theme xl:py-3">
                <div className="px-2 text-lg uppercase 2xl:text-xl text-textblue-theme whitespace-nowrap">
                  SOURCES
                </div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">
                  {/* 29 */}
                  {sourceList ? (
                    Object.keys(sourceList)?.length
                  ) : (
                    <span className="text-transparent">0</span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-b-lg shadow-lg xl:p-3">
                <div className="uppercase text-secondblue-theme">Federated</div>
                {/* <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div
                    className="bg-green-theme h-2.5 rounded-full sourceFederated"
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">
                    <span id="nbr">80</span> <span>%</span>
                  </div>
                  <div className="text-red2-theme">
                    <span id="pbr">20</span> <span>%</span>
                  </div>
                </div> */}
                <HorizontalProgressBar
                  // value={parseInt(numberOfProjects.current)}
                  value={fetchFederatedPercentage(sourceList, true)}
                  id="federated-source"
                />
              </div>
            </div>
            <div className="flex-1 text-center">
              <div className="pt-1 pb-2 mb-2 rounded-t-lg bg-gray2-theme xl:py-3">
                <div className="px-2 text-lg uppercase 2xl:text-xl text-textblue-theme whitespace-nowrap">
                  VIRTUAL DATASETS
                </div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">
                  {/* 65 */}

                  {vdsList ? (
                    Object.keys(vdsList)?.length
                  ) : (
                    <span className="">0</span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-b-lg shadow-lg xl:p-3">
                <div className="uppercase text-secondblue-theme">Federated</div>
                {/* <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div
                    className="bg-green-theme h-2.5 rounded-full"
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div> */}
                <HorizontalProgressBar
                  // value={parseInt(numberOfProjects.current)}
                  value={fetchFederatedPercentage(vdsList)}
                  id="federated-vdes"
                />
              </div>
            </div>
            <div className="flex-1 text-center">
              <div className="pt-1 pb-2 mb-2 rounded-t-lg bg-gray2-theme xl:py-3">
                <div className="px-2 text-lg uppercase 2xl:text-xl text-textblue-theme whitespace-nowrap">
                  MODELS
                </div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">
                  {/* 12 */}

                  {modelList ? (
                    Object.keys(modelList)?.length
                  ) : (
                    <span className="">0</span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-b-lg shadow-lg xl:p-3">
                <div className="uppercase text-secondblue-theme">Federated</div>
                <HorizontalProgressBar
                  // value={parseInt(numberOfProjects.current)}
                  value={fetchFederatedPercentage(modelList)}
                  id="federated-models"
                />
              </div>
            </div>
            <div className="flex-1 text-center">
              <div className="pt-1 pb-2 mb-2 rounded-t-lg bg-gray2-theme xl:py-3">
                <div className="px-2 text-lg uppercase 2xl:text-xl text-textblue-theme whitespace-nowrap">
                  WORD Embeddings
                </div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">
                  {/* 8 */}
                  {weList ? (
                    Object.keys(weList)?.length
                  ) : (
                    <span className="text-transparent">0</span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-b-lg shadow-lg xl:p-3">
                <div className="uppercase text-secondblue-theme">Federated</div>
                {/* <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div
                    className="bg-green-theme h-2.5 rounded-full"
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div> */}
                <HorizontalProgressBar
                  // value={parseInt(numberOfProjects.current)}
                  value={weList ? fetchFederatedPercentage(weList) : 0}
                  id="federated-we"
                />
              </div>
            </div>
            <div className="flex-1 text-center">
              <div className="pt-1 pb-2 mb-2 rounded-t-lg bg-gray2-theme xl:py-3">
                <div className="px-2 text-lg uppercase 2xl:text-xl text-textblue-theme whitespace-nowrap">
                  MODEL TRAININGS
                </div>
                <div className="lineHeight-1.5rem xl:lineHeight-normal text-30px xl:text-36px 2xl:text-42px text-brightblue-theme">
                  {/* 8 */}
                  {trainList ? (
                    Object.keys(trainList)?.length
                  ) : (
                    <span className="text-transparent">0</span>
                  )}
                </div>
              </div>
              <div className="px-3 py-2 bg-white rounded-b-lg shadow-lg xl:p-3">
                <div className="uppercase text-secondblue-theme">Federated</div>
                {/* <div className="w-full bg-red2-theme rounded-full h-2.5 my-1 xl:my-3">
                  <div
                    className="bg-green-theme h-2.5 rounded-full"
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <div className="flex justify-between">
                  <div className="text-green-theme">80%</div>
                  <div className="text-red2-theme">20%</div>
                </div> */}
                <HorizontalProgressBar
                  // value={parseInt(numberOfProjects.current)}
                  value={fetchFederatedPercentage(trainList)}
                  id="federated-train"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 text-lg font-medium">
        Projects:
        {!isProjectLoading && (
          <span className="w-10 h-10 p-1 ml-1 font-bold bg-gray2-theme">
            {numberOfProjects.current}
          </span>
        )}
      </div>
      <div className="flex flex-wrap gap-4 mt-5 mb-2">
        <div className="flex justify-between px-2 py-1 bg-white border-2 rounded-md border-zinc-400 rounde-md">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="relative w-6 h-6 text-gray-400 transition duration-100 cursor-pointer hover:text-blue-400 top-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </span>
          <AutoCompleteSearch
            className="flex-grow ml-1 text-gray-600 outline-none focus:text-blue-600"
            placeholder="Search"
            searchList={completeProjectArray}
            onSelectSearch={onSelectSearchItem}
            closeSearch={closeSearch}
            setCloseSearch={setCloseSearch}
            style={{ width: "150px" }}
          />
          <button
            type="button"
            onClick={onClickCloseSearch}
            className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
          >
            <span className="sr-only">Close menu</span>

            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/* <div className="flex items-center">
          Sort by:
          <select className="p-2 ml-2 bg-white border-2 border-zinc-400">
            <option>Last Created</option>
          </select>
        </div> */}
        <ProjectSort SortBy={SortBy} setSortBy={setSortBy} />
        <div className="flex items-center ml-auto">
          <button
            type="submit"
            className="inline-flex justify-center p-2 text-sm font-medium text-white uppercase border border-transparent rounded shadow-sm bg-brightblue-theme hover:bg-indigo-700 focus:outline-none"
            onClick={() => setShowModal(true)}
          >
            <img
              className="h-5 mr-1"
              src={process.env.PUBLIC_URL + "/images/round-plus-white.svg"}
              alt=""
            />{" "}
            new project
          </button>
          {showModal ? (
            <AddProject showModal={showModal} setShowModal={setShowModal} />
          ) : null}
        </div>
      </div>
      {isProjectLoading ? (
        <div className="flex flex-wrap justify-center min-w-full mt-5 mb-3 align-middle">
          <Loader
            type="spinner-circle"
            bgColor={"gray"}
            // title={"spinner-circle"}
            // color="black"
            size={80}
          />
        </div>
      ) : (
        <div className="flex flex-wrap mt-5 mb-3">
          {searchList.length > 0 ? (
            <Project projectList={searchList} />
          ) : (
            <Project projectList={projectList} />
          )}
        </div>
      )}

      {searchList.length === 0 && (
        <button
          type="submit"
          disabled={arrayInfo.arrayData.length === 0 || isProjectLoading}
          onClick={() => onLoadMore()}
          style={
            arrayInfo.arrayData.length === 0 || isProjectLoading
              ? { backgroundColor: "lightgray" }
              : {}
          }
          className="inline-flex justify-center p-2 text-sm font-medium text-white uppercase border border-transparent rounded shadow-sm bg-green-theme hover:bg-indigo-700 focus:outline-none"
        >
          load more
        </button>
      )}
    </div>
  );
};

export default HomePage;
