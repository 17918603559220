import "../../../App.css";
import { connect } from "react-redux";
import ViewSource from "./ViewSource";
import { fetchDataViewSourceList } from "../../../Redux/actions";

const mapDispatchToProps = (dispatch) => ({
  fetchDataViewSourceList: () => fetchDataViewSourceList(dispatch),
});

const mapStateToProps = (state) => {
  return {
    isLoading: state.DataView.isSourceDataLoading,
    sourceList: state.DataView.sourceList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSource);
