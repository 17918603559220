import {
  STORE_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  PROJECTS_READ,
  PROJECT_LIST_INIT,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_ERROR,
  VDS_LIST_INIT,
  VDS_LIST_ERROR,
  VDS_LIST_SUCCESS,
  TRAIN_LIST_INIT,
  TRAIN_LIST_SUCCESS,
  TRAIN_LIST_ERROR,
  MODEL_LIST_INIT,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_ERROR,
  SOURCE_LIST_INIT,
  SOURCE_LIST_SUCCESS,
  SOURCE_LIST_ERROR,
  WE_LIST_INIT,
  WE_LIST_SUCCESS,
  WE_LIST_ERROR,
  SEARCH_LIST_INIT,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_ERROR,
  PROJECTOVERVIEW_PROJECT_LIST_INIT,
  PROJECTOVERVIEW_PROJECT_LIST_SUCCESS,
  PROJECTOVERVIEW_PROJECT_LIST_ERROR,
} from "./../actionTypes";

export const ProjectReducer = (
  state = {
    ProjectsStore: [],
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_PROJECT:
      return {
        ...state,
        ProjectsStore: payload,
      };
    case CREATE_PROJECT:
      // console.log("Data appended", [...state.ProjectsStore, payload]);
      return {
        ...state,
        ProjectsStore: [...state.ProjectsStore, payload],
      };
    case UPDATE_PROJECT:
      let newdata = state.ProjectsStore.map((item) =>
        item.id === payload.id ? payload : item
      );
      // console.log("last record updated new data", newdata);
      return {
        ...state,
        ProjectsStore: newdata,
      };
    case DELETE_PROJECT:
      let deleteddata = state.ProjectsStore.filter(
        (item) => item.id !== payload.pid
      );
      // console.log("deleted data", deleteddata);
      return {
        ...state,
        ProjectsStore: deleteddata,
      };

    default:
      return state;
  }
};

export const mockProjectReducer = (
  state = {
    isLoading: true,
    projects: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECTS_READ:
      return {
        ...state,
        isLoading: false,
        projects: payload,
      };
    default:
      return state;
  }
};

export const ListReducer = (
  state = {
    loading: true,
    isProjectLoading: null,
    // projectList: null,
    vdsList: null,
    trainList: [],
    modelList: null,
    sourceList: [],
    weList: [],
    searchQueryList: [],
    isProjectOverviewLoading: null,
    overviewList: null,
    error: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_LIST_INIT:
      return {
        ...state,
        isProjectLoading: true,
      };
    case PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isProjectLoading: false,
        projectList: payload,
      };
    case PROJECT_LIST_ERROR:
      return {
        ...state,
        isProjectLoading: false,
        projectList: [],
        error: payload,
      };
    case VDS_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case VDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vdsList: payload,
        error: null,
      };
    case VDS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        vdsList: [],
        error: payload,
      };
    case TRAIN_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case TRAIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        trainList: payload,
        error: null,
      };
    case TRAIN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        trainList: [],
        error: payload,
      };
    case MODEL_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case MODEL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        modelList: payload,
        error: null,
      };
    case MODEL_LIST_ERROR:
      return {
        ...state,
        loading: false,
        modelList: [],
        error: payload,
      };
    case SOURCE_LIST_INIT:
      return {
        ...state,
        loading: false,
      };
    case SOURCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceList: payload,
        error: null,
      };
    case SOURCE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        sourceList: [],
        error: payload,
      };
    case WE_LIST_INIT:
      return {
        ...state,
        loading: false,
      };
    case WE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        weList: payload,
        error: null,
      };
    case WE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        weList: [],
        error: payload,
      };
    case SEARCH_LIST_INIT:
      return {
        ...state,
        loading: false,
      };
    case SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        searchQueryList: payload,
        error: null,
      };
    case SEARCH_LIST_ERROR:
      return {
        ...state,
        loading: false,
        searchQueryList: [],
        error: payload,
      };
    case PROJECTOVERVIEW_PROJECT_LIST_INIT:
      return {
        ...state,
        isProjectOverviewLoading: true,
      };
    case PROJECTOVERVIEW_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isProjectOverviewLoading: false,
        overviewList: payload,
      };
    case PROJECTOVERVIEW_PROJECT_LIST_ERROR:
      return {
        ...state,
        isProjectOverviewLoading: false,
        overviewList: [],
        error: payload,
      };
    default:
      return state;
  }
};
