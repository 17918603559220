import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FileUploadWrapper, getImagefromURL } from "reactjs-component-library";
import { SortAscendingOrder } from "../../helper";
import { api } from "../../services/common";
import ProjectDetailsTab from "./ProjectDetailsTab";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateProjectAction } from "./../../Redux/actions/ProjectAction";
import { imgLoader } from "../../asset/images";

const ProjectOverview = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { ProjectData } = state;
  let ProjectRecords = useSelector((state) =>
    state.ProjectReducer.ProjectsStore.filter(
      (option) => option.id === ProjectData.id
    )
  );
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [EditTitle, setEditTitle] = useState(false);
  const [EditDescription, setEditDescription] = useState(false);
  const [DeleteProject, setDeleteProject] = useState(false);
  const [VdsesData, setVdsesData] = useState([]);
  const [ModelData, setModelData] = useState([]);
  const [SearchQueryData, setSearchQueryData] = useState([]);
  const [WordEmbeddingData, setWordEmbeddingData] = useState([]);
  const [ModelTrainingData, setModelTrainingData] = useState([]);
  const [image, setImage] = useState("");
  const [copiedIDToClipboard, setCopiedIDToClipboard] = useState(false);

  // console.log("ProjectData", ProjectData);
  useEffect(() => {
    if (state !== null) {
      ReadVirtualDatasetsData();
      ReadSearchQueryData();
      ReadModelsData();
      ReadWordEmbeddingsData();
      ReadModelTrainingData();
      setImage(
        ProjectData["image.binary"]
          ? getImagefromURL(ProjectData["image.binary"])
          : "https://images.unsplash.com/photo-1674570995544-4e6260871f88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      );
    }
  }, [state]);

  const UpdateProjectHandler = async (event) => {
    await api("/lucd/project/update", {
      method: "POST",
      body: {
        uid: ProjectData.id,
        description:
          Description !== "" ? Description : ProjectRecords[0].description,
        name: Title !== "" ? Title : ProjectRecords[0].name,
        vdses: ProjectData.vdses,
        models: ProjectData.models,
      },
    })
      .then((res) => {
        const tmpArray = [];
        Object.keys(res.data).forEach((key) => {
          if (key !== "default") {
            tmpArray.push({ id: key, ...res.data[key] });
          }
        });
        dispatch(updateProjectAction(tmpArray[0]));
      })
      .catch((error) => console.log("error", error));
  };

  const DeleteProjectHandler = async () => {
    let payload = {
      uid: ProjectData.id,
    };
    await api("/lucd/project/delete", {
      method: "POST",
      body: { ...payload },
    })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
    navigate("/");
  };

  const ReadModelsData = async () => {
    const projectList = props.overViewList || props.projectList;
    const dataItem = projectList[ProjectData.id];
    if (dataItem.models && Object.keys(props?.modelList).length) {
      let ProjectModals = [];
      Object.keys(dataItem.models).forEach((item) => {
        const id = dataItem.models[item];
        if (props?.modelList[id]) {
          let name = props?.modelList[id]["model_name"].trim();
          name = name.toLowerCase();
          ProjectModals.push({ ...props.modelList[id], name });
        }
      });
      setModelData(SortAscendingOrder(ProjectModals));
    } else {
      setModelData([]);
    }
  };

  const ReadVirtualDatasetsData = async () => {
    let vdsData = [];
    if (ProjectData.vdses && Object.keys(props?.vdsList).length) {
      Object.keys(ProjectData.vdses).forEach((item) => {
        const id = ProjectData.vdses[item];
        if (props.vdsList[id]) {
          let name = props?.vdsList[id]["name"].trim();
          name = name.toLowerCase();
          vdsData.push({ ...props.vdsList[id], id, name });
        }
      });
      setVdsesData(SortAscendingOrder(vdsData));
    } else {
      setVdsesData([]);
    }
  };

  const ReadSearchQueryData = async () => {
    let srchData = [];
    if (ProjectData.searches && Object.keys(props?.searchQueryList).length) {
      Object.keys(ProjectData.searches).forEach((item) => {
        const id = ProjectData.searches[item];
        if (props.searchQueryList[id]) {
          let name = props?.searchQueryList[id]["name"].trim();
          name = name.toLowerCase();
          srchData.push({ ...props.searchQueryList[id], id, name });
        }
      });
      setSearchQueryData(SortAscendingOrder(srchData));
    } else {
      setSearchQueryData([]);
    }
  };

  const ReadWordEmbeddingsData = async () => {
    let wordEmbeddingData = [];
    if (ProjectData.assets && Object.keys(props?.weList).length) {
      Object.keys(ProjectData.assets).forEach((item) => {
        const id = ProjectData.assets[item];
        if (props.weList[id]) {
          let name = props?.weList[id]["name"].trim();
          name = name.toLowerCase();
          wordEmbeddingData.push({ ...props.weList[id], id, name });
        }
      });
      setWordEmbeddingData(SortAscendingOrder(wordEmbeddingData));
    } else {
      setWordEmbeddingData([]);
    }
  };

  const ReadModelTrainingData = async () => {
    let modelTrainingData = [];
    if (ProjectData.trainings && Object.keys(props?.trainList).length) {
      Object.keys(ProjectData.trainings).forEach((item) => {
        const id = ProjectData.trainings[item];
        if (props.trainList[id]) {
          const record = props?.modelList[props?.trainList[id]?.model]
            ? props?.modelList[props?.trainList[id]?.model]
            : null;
          modelTrainingData.push({ ...props.trainList[id], id, record });
        }
      });
      setModelTrainingData(SortAscendingOrder(modelTrainingData));
    } else {
      setModelTrainingData([]);
    }
  };

  const copyProjectID = async () => {
    var copyText = document.getElementById("clipboardText");
    copyText.focus();
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        setCopiedIDToClipboard(true);
        setTimeout(() => {
          setCopiedIDToClipboard(false);
        }, 3000);
      })
      .catch(() => {
        alert("something went wrong");
      });

    // const res = await navigator.clipboard.readText();
    // console.log("res", res);
  };

  const onFileInfo = async (info) => {
    const { url } = info;
    if (url) {
      setImage(imgLoader);
      await api("/lucd/project/update", {
        method: "POST",
        body: {
          uid: ProjectData.id,
          description:
            Description !== "" ? Description : ProjectRecords[0].description,
          name: Title !== "" ? Title : ProjectRecords[0].name,
          vdses: ProjectData.vdses,
          models: ProjectData.models,
          "image.binary": url,
        },
      })
        .then((res) => {
          const tmpArray = [];
          setImage(url);
          Object.keys(res.data).forEach((key) => {
            if (key !== "default") {
              tmpArray.push({ id: key, ...res.data[key], "image.binary": url });
            }
          });
          dispatch(updateProjectAction(tmpArray[0]));
        })
        .catch((error) => {
          console.log("error", error);
          setImage(
            ProjectData["image.binary"]
              ? getImagefromURL(ProjectData["image.binary"])
              : "https://images.unsplash.com/photo-1674570995544-4e6260871f88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          );
        });
    }
  };

  const refreshPage = async ({
    isModel = false,
    isQuery = false,
    isVdses = false,
    isWordEmbedding = false,
    isModelTraining = false,
  }) => {
    if (isModel) {
      setModelData(["isLoading"]);
      await props.fetchModelsRefresh();
      ReadModelsData();
    }
    if (isQuery) {
      setSearchQueryData(["isLoading"]);
      await props.fetchQueryRefresh();
      ReadSearchQueryData();
    }
    if (isVdses) {
      setVdsesData(["isLoading"]);
      await props.fetchVdsesRefresh();
      ReadVirtualDatasetsData();
    }
    if (isWordEmbedding) {
      setWordEmbeddingData(["isLoading"]);
      await props.fetchWordEmbeddingsRefresh();
      ReadWordEmbeddingsData();
    }
    if (isModelTraining) {
      setModelTrainingData(["isLoading"]);
      await props.fetchModelTrainingRefresh();
      ReadModelTrainingData();
    }
  };

  return (
    <div className="p-5">
      <div className="flex flex-wrap">
        <div className="w-full md:w-full lg:w-9/12 xl:w-8/12 2xl:w-8/12">
          <div className="pl-2 rounded-lg shadow-md bg-brightblue-theme sm:h-72">
            <div className="flex flex-wrap -mr-1 bg-white rounded-r-lg">
              <div className="w-full sm:w-1/3 rounded-r-lg group relative hover:after:content-[''] hover:after:inset-0 after:hover:bg-slate-50/[0.6] hover:after:z-0 hover:after:w-full hover:after:h-full hover:after:absolute">
                <FileUploadWrapper
                  name="avatar"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                  inputFileTypes={[
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                    "image/gif",
                    "image/webp",
                  ]}
                  errorMessage="Cannot upload file. File size > 200KB"
                  fileLimit={200}
                  sizeLimitMeasure="KB"
                  onFileInfo={onFileInfo}
                  showFileList={false}
                >
                  <button
                    className="absolute z-10 hidden w-32 h-10 p-2 text-sm uppercase transition-all duration-150 ease-linear -translate-x-1/2 -translate-y-1/2 border-2 rounded background-transparent inset-1/2 border-brightblue-theme text-brightblue-theme group-hover:inline-block"
                    type="button"
                  >
                    change COVER
                  </button>
                </FileUploadWrapper>
                {/* <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[302/273] object-cover object-center h-72" /> */}
                <img
                  src={
                    image ||
                    "https://images.unsplash.com/photo-1674570995544-4e6260871f88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  }
                  alt=""
                  className="w-full aspect-[302/273] object-cover object-center h-48 sm:h-72"
                />
              </div>
              <div className="flex flex-col w-full py-3 pl-2 pr-2 sm:w-2/3 sm:pl-5">
                <div className="relative flex flex-wrap items-center justify-end gap-2 pb-3">
                  <button
                    className="inline-flex justify-center p-2 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                    type="button"
                    onClick={() => {
                      navigate("/openProject", {
                        state: { ProjectData: ProjectData },
                      });
                    }}
                  >
                    Open Project
                  </button>
                  <div className="relative">
                    <button
                      id="clipboardText"
                      value={ProjectData.id}
                      className="px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
                      type="button"
                      onClick={() => copyProjectID()}
                    >
                      COPY Project ID
                    </button>
                    {copiedIDToClipboard && (
                      <div className="absolute left-0 flex p-2 bg-white shadow-md">
                        <img
                          src={process.env.PUBLIC_URL + "/images/tick.svg"}
                          alt=""
                          className="mr-1"
                        />
                        Project ID copied
                      </div>
                    )}
                  </div>
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme group"
                    type="button"
                    onClick={() => setDeleteProject(true)}
                  >
                    delete
                  </button>
                  {DeleteProject && (
                    <div className="absolute z-20 p-3 transition duration-150 ease-in-out bg-white rounded shadow-lg mt-14 w-70 right-16">
                      <p className="pb-1 m-0 text-sm font-bold text-gray-800">
                        Are you sure you want to delete this project?
                      </p>
                      <em className="text-sm">
                        This action will not delete project artifacts.
                      </em>
                      <div className="flex justify-end mt-4">
                        <div className="flex items-center">
                          <button
                            className="p-2 mr-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-slate-600 text-slate-600"
                            type="button"
                            onClick={() => setDeleteProject(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="p-2 text-sm text-white uppercase transition-all duration-150 ease-linear border-2 rounded bg-pourpre-theme border-pourpre-theme"
                            type="button"
                            onClick={() => {
                              setDeleteProject(false);
                              DeleteProjectHandler();
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <h2 className="mb-2 text-sm font-medium leading-8 uppercase group">
                  {EditTitle === true ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        name="projectName"
                        id="projectName"
                        // autoComplete="given-name"
                        placeholder="Enter Project Name"
                        defaultValue={ProjectRecords[0].name}
                        className="block mt-1 border-gray-300 grow focus:border-indigo-500 focus:ring-indigo-500"
                        onChange={(e) => setTitle(e.target.value)}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Enter") {
                        //     UpdateProjectHandler(event);
                        //   }
                        // }}
                      />
                      <div className="flex">
                        <button
                          className="inline-flex justify-center p-2 ml-1 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                          type="button"
                          onClick={() => {
                            setEditTitle(false);
                            UpdateProjectHandler();
                          }}
                        >
                          Save
                        </button>
                        <button
                          className="p-2 mr-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-slate-600 text-slate-600"
                          type="button"
                          onClick={() => {
                            setEditTitle(false);
                            setTitle("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {ProjectRecords[0].name}
                      <a
                        href="javascript:void(0);"
                        className="relative hidden group-hover:inline-block group2"
                        onClick={() => {
                          setTitle(ProjectRecords[0].name);
                          setEditDescription(false);
                          setEditTitle(true);
                        }}
                      >
                        <span className="pointer-events-none normal-case absolute -top-10 left-1/6 -translate-x-1/6 whitespace-nowrap rounded bg-blue-theme px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/6 before:top-full before:-translate-x-1/6 before:border-4 before:border-transparent before:content-[''] group2-hover:opacity-100">
                          Edit project name
                        </span>
                        <img
                          src={process.env.PUBLIC_URL + "/images/edit.png"}
                          alt=""
                          className="ml-1"
                        />
                      </a>
                    </>
                  )}
                </h2>
                <p className="text-sm font-medium leading-8 text-zinc-400 group">
                  {EditDescription === true ? (
                    <div className="flex items-center">
                      <input
                        type="text"
                        name="projectDescription"
                        id="projectDescription"
                        // autoComplete="given-name"
                        placeholder="Enter Project Description"
                        defaultValue={ProjectRecords[0].description}
                        className="block mt-1 border-gray-300 grow focus:border-indigo-500 focus:ring-indigo-500"
                        onChange={(e) => setDescription(e.target.value)}
                        // onKeyDown={(event) => {
                        //   if (event.key === "Enter") {
                        //     UpdateProjectHandler(event);
                        //   }
                        // }}
                      />
                      <div className="flex">
                        <button
                          className="inline-flex justify-center p-2 ml-1 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                          type="button"
                          onClick={() => {
                            setEditDescription(false);
                            UpdateProjectHandler();
                          }}
                        >
                          Save
                        </button>
                        <button
                          className="p-2 mr-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-slate-600 text-slate-600"
                          type="button"
                          onClick={() => {
                            setEditDescription(false);
                            setDescription("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {ProjectRecords[0].description
                        ? ProjectRecords[0].description
                        : "Your description goes here"}
                      <a
                        href="javascript:void(0);"
                        className="relative hidden group-hover:inline-block group2"
                        onClick={() => {
                          setDescription(ProjectRecords[0].description);
                          setEditTitle(false);
                          setEditDescription(true);
                        }}
                      >
                        <span className="pointer-events-none absolute -top-10 left-1/6 -translate-x-1/6 whitespace-nowrap rounded bg-blue-theme px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/6 before:top-full before:-translate-x-1/6 before:border-4 before:border-transparent before:content-[''] group2-hover:opacity-100">
                          Edit project description
                        </span>
                        <img
                          src={process.env.PUBLIC_URL + "/images/edit.png"}
                          alt=""
                          className="ml-1"
                        />
                      </a>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          <ProjectDetailsTab
            ProjectData={ProjectData}
            VdsesData={VdsesData}
            ModelData={ModelData}
            SearchQueryData={SearchQueryData}
            WordEmbeddingData={WordEmbeddingData}
            ModelTrainingData={ModelTrainingData}
            createProjectModel={props.createProjectModel}
            projectAddRemoveArtifact={props.projectAddRemoveArtifact}
            refreshPage={refreshPage}
          />
        </div>
        <div className="w-full pl-0 mt-4 md:w-full lg:w-3/12 xl:w-4/12 2xl:w-4/12 lg:pl-5 lg:mt-0">
          <div className="p-3 bg-white rounded-lg shadow-lg">
            <h2 className="pb-2 mb-1 text-xl border-b border-gray-400 text-textblue-theme">
              Your Recent Activity
            </h2>
            <div className="pr-1 overflow-y-auto lg:h-screen">
              <p className="uppercase text-secondblue-theme">TODAY</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>{" "}
                    <span className="ml-1 font-semibold text-pourpre-theme">
                      Monterey Bay Course
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <p className="uppercase text-secondblue-theme">March 31, 2022</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
