import moment from "moment";

export const sortDateDescending = (arr, searchKey = "createdAt") => {
  if (arr.length) {
    const sortedTableArray = arr.sort(
      (a, b) => moment(a[searchKey]).valueOf() - moment(b[searchKey]).valueOf()
    );
    return sortedTableArray.reverse();
  }
  return [];
};

export const sortDateModifiedDescending = (arr) => {
  if (arr.length) {
    const sortedTableArray = arr.sort(
      (a, b) =>
        moment(a["modifiedAt"]).valueOf() - moment(b["modifiedAt"]).valueOf()
    );
    return sortedTableArray.reverse();
  }
  return [];
};

export const SortAscendingOrder = (arr) => {
  if (arr.length) {
    let ascendingResult = arr.sort((a, b) => (a.name > b.name ? 1 : -1));
    // console.log("asc sorted", ascendingResult);
    return ascendingResult;
  }
  return [];
};

export const SortDescendingOrder = (arr) => {
  if (arr.length) {
    let descendingResult = arr.sort((a, b) => (a.name < b.name ? 1 : -1));
    // console.log("desc sorted", descendingResult);
    return descendingResult;
  }
  return [];
};

export const SortModalDataAscendingOrder = (arr) => {
  if (arr.length) {
    let ascendingModelResult = arr.sort((a, b) =>
      a.model_name < b.model_name ? 1 : -1
    );
    console.log("asc sorted", ascendingModelResult);
    return ascendingModelResult;
  }
  return [];
};

export const SortModalDataDescendingOrder = (arr) => {
  if (arr.length) {
    let descendingModelResult = arr.sort((a, b) =>
      a.model_name > b.model_name ? 1 : -1
    );
    console.log("desc sorted", descendingModelResult);
    return descendingModelResult;
  }
  return [];
};

// export const sortDateAscending = (arr) => {
//   if (arr.length) {
//     const sortedTableArray = arr.sort(
//       (a, b) =>
//         moment(a["createdAt"]).valueOf() - moment(b["createdAt"]).valueOf()
//     );
//     return sortedTableArray;
//   }
//   return [];
// };

export const readList = (list, searchKey = "name") => {
  const { statusCode } = list;
  let lastCreated = [];
  if (statusCode === 400) {
    return [];
  } else {
    const tmpArr = [];
    if (Object.keys(list).length > 0) {
      Object.keys(list).forEach((item) => {
        let name = list[item][searchKey].trim();
        name = name.toLowerCase();
        tmpArr.push({
          id: item,
          ...list[item],
          name,
        });
      });
    }
    lastCreated = [...tmpArr];
  }
  return {
    data: [...lastCreated],
  };
};

export const readSearchList = async (
  list,
  searchKey = "name",
  dateKey = "createdAt"
) => {
  let lastCreated = [];

  const tmpArr = [];
  if (list && Object.keys(list).length > 0) {
    Object.keys(list).forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(list[item], searchKey)) {
        let name = list[item][searchKey]?.trim();
        name = name?.toLowerCase();
        tmpArr.push({
          id: item,
          ...list[item],
          name: name,
          searchName: list[item]?.name,
        });
      } else {
        let name = item.trim();
        name = name?.toLowerCase();
        tmpArr.push({
          id: item,
          ...list[item],
          name: name,
          searchName: item,
        });
      }
    });
  }
  lastCreated = sortDateDescending(tmpArr, dateKey);

  return {
    data: [...lastCreated],
  };
};

export const hasNull = (infoObject) => {
  for (var member in infoObject) {
    if (infoObject[member] === "" || infoObject[member] === null) {
      return true;
    }
  }
  return false;
};
