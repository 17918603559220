import React from 'react';

export default function dataSources() {
  return (
    <div className='virtualdatasets p-2'>
        <div className='flex'>
            <div className='flex items-center'>
                <a className='text-slate-900 font-semibold font-GreycliffCF' href="">Data</a>
                <div className='mx-2 text-textgray-theme'> &#10095; </div>
                <div className='font-semibold text-textgray2-theme'>Sources</div>
            </div>
            <div className='ml-auto'>
                <button className="ml-auto background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 ease-linear transition-all duration-150 flex" type="button"><img className="mr-1" src={process.env.PUBLIC_URL + "/images/refresh.svg"} alt=""/> refresh</button>
            </div>
        </div>        
        <div className='flex mt-2'>
            <div className='flex items-center'>
                <h1 className='text-xl text-textblue-theme'>Sources</h1>
            </div>
            <div className='ml-auto'>
                <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white w-52">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </span>
                    <select className="flex-grow outline-none text-gray-600 ml-1 appearance-none px-2">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </select>
                    <img className="w-4 ml-1 cursor-pointer" src={process.env.PUBLIC_URL + "/images/close-gray.svg"} alt=""/>
                </div>
            </div>
        </div>
        <div className='flex mt-2 items-center'>
            Sort by :
            <div className="border-2 border-zinc-400 ml-2 py-1 px-2 flex justify-between rounde-md rounded-md bg-white">
                <select className="flex-grow outline-none text-gray-600 p-1 w-52">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                </select>
            </div>
        </div>
        <div className='bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]'>
            <table class="min-w-full border-separate border-spacing-0 text-left">
                <thead class="text-textblue uppercase font-GreycliffCF font-bold">
                    <tr>
                        <th scope="col" class="p-2">name</th>
                        <th scope="col" class="p-2">Size</th>
                        <th scope="col" class="p-2">Records</th>
                        <th scope="col" class="p-2"># of models</th>
                        <th scope="col" class="p-2">Ingest date</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">Pilote Engine</td>
                        <td class="border-y border-[#919195] p-2">0 B</td>
                        <td class="border-y border-[#919195] p-2">1</td>
                        <td class="border-y border-[#919195] p-2">-</td>                        
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">Dec. 31, 2014</td>
                    </tr>
                    <tr><td colSpan={9} className="py-1"></td></tr>
                    <tr>
                        <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">Name of the Source</td>
                        <td class="border-y border-[#919195] p-2">0 B</td>
                        <td class="border-y border-[#919195] p-2">1</td>
                        <td class="border-y border-[#919195] p-2">-</td>                        
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">Dec. 31, 2014</td>
                    </tr>
                    <tr><td colSpan={9} className="py-1"></td></tr>
                    <tr>
                        <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">Name of the Source</td>
                        <td class="border-y border-[#919195] p-2">215.34 KB</td>
                        <td class="border-y border-[#919195] p-2">1</td>
                        <td class="border-y border-[#919195] p-2">-</td>                        
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">Dec. 31, 2014</td>
                    </tr>
                    <tr><td colSpan={9} className="py-1"></td></tr>
                    <tr>
                        <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">Name of the Source</td>
                        <td class="border-y border-[#919195] p-2">234.65 MB</td>
                        <td class="border-y border-[#919195] p-2">1</td>
                        <td class="border-y border-[#919195] p-2">-</td>                        
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">Dec. 31, 2014</td>
                    </tr>
                    <tr><td colSpan={9} className="py-1"></td></tr>
                    <tr>
                        <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">Name of the Source</td>
                        <td class="border-y border-[#919195] p-2">0 B</td>
                        <td class="border-y border-[#919195] p-2">1</td>
                        <td class="border-y border-[#919195] p-2">-</td>                        
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">Dec. 31, 2014</td>
                    </tr>                    
                </tbody>
            </table>
        </div>
    </div>
  );
}
