import React, { useRef, useEffect, useState } from "react";
import Loader from "react-js-loader";
import moment from "moment";

const ModelTrainingsDetails = ({ ModelTrainingData, refreshPage }) => {
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const isLoading =
    ModelTrainingData &&
    ModelTrainingData.length &&
    ModelTrainingData[0] === "isLoading";

  if (isLoading) {
    return (
      <Loader
        type="spinner-circle"
        bgColor={"gray"}
        // title={"spinner-circle"}
        // color="black"
        size={50}
      />
    );
  }

  return (
    <>
      <div
        className="flex"
        role="button"
        onClick={() => refreshPage({ isModelTraining: true })}
        disabled={isRefreshDisabled}
      >
        <button
          className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
          type="button"
        >
          <img
            className="mr-1"
            src={process.env.PUBLIC_URL + "/images/refresh.svg"}
            alt=""
          />{" "}
          refresh
        </button>
      </div>
      <div>
        <table className="min-w-full border-separate border-spacing-0 text-left">
          <thead className="text-xs uppercase">
            <tr>
              <th scope="col" className="p-2">
                Name
              </th>
              <th scope="col" className="p-2">
                Start time
              </th>
              <th scope="col" className="p-2">
                Stop time
              </th>
              <th scope="col" className="p-2">
                Duration
              </th>
              <th scope="col" className="p-2">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {ModelTrainingData.length > 0
              ? ModelTrainingData.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                          {item?.record !== null && item?.record !== undefined
                            ? item?.record.model_name
                            : ""}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {moment(item?.training_starttime).format(
                            "hh:mm:ss.SS"
                          )}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {moment(item?.training_endtime).format("hh:mm:ss.SS")}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {moment(item?.training_duration).format(
                            "hh:mm:ss.SS"
                          )}
                        </td>
                        <td className="flex border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2 text-right">
                          {item?.status === "ERROR" ? (
                            <img
                              className="h-5"
                              src={
                                process.env.PUBLIC_URL + "/images/failed.svg"
                              }
                              alt=""
                            />
                          ) : item?.status === "CREATING" ? (
                            <img
                              className="h-5"
                              src={
                                process.env.PUBLIC_URL + "/images/creating.svg"
                              }
                              alt=""
                            />
                          ) : item?.status === "TRAINING COMPLETE" ? (
                            <img
                              className="h-5"
                              src={
                                process.env.PUBLIC_URL +
                                "/images/check-green.svg"
                              }
                              alt=""
                            />
                          ) : null}
                          {item?.status === "ERROR"
                            ? "Failed"
                            : item?.status === "TRAINING COMPLETE"
                            ? "Completed"
                            : item?.status === "CREATING"
                            ? "Creating"
                            : item?.status}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="py-1"></td>
                      </tr>
                    </>
                  );
                })
              : "No records found!"}
          </tbody>
        </table>
      </div>
      <div className="flex mt-3">
        <button
          className="ml-auto inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          CREATE NEW MODEL TRAINING
        </button>
      </div>
    </>
  );
};

export default ModelTrainingsDetails;
