import React, { useState } from "react";
import moment from "moment";
import { api } from "./../../services/common";
import { useOidcUser } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createProjectAction } from "./../../Redux/actions/ProjectAction";

function AddProject({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { oidcUser } = useOidcUser();
  const [Description, setDescription] = useState("");
  const [Title, setTitle] = useState("");

  const createProjectHandler = async () => {
    let bodyParameters = {
      assets: [],
      author: oidcUser.name,
      description: Description,
      models: [],
      name: Title,
      searches: [],
      vdses: [],
    };
    let updateData = await api("/lucd/project/create", {
      method: "POST",
      body: bodyParameters,
    });
    const tmpArray = [];
    Object.keys(updateData.data).forEach((key) => {
      if (key !== "default") {
        tmpArray.push({ id: key, ...updateData.data[key] });
      }
    });
    dispatch(createProjectAction(tmpArray[0]));
    navigate("/projectoverview", {
      state: { ProjectData: tmpArray[0] },
    });

    // dispatch(createProjectAction(body));
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto min-w-[75%] md:min-w-[50%] xl:min-w-[25%]">
          {/*content*/}
          <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between bg-brightblue-theme border-b border-solid border-slate-200 rounded-t-[20px] text-white">
              <h3 className="py-3 pl-3 m-0 font-semibold text-white">
                Create New Project
              </h3>
              <button
                className="ml-auto mr-3 text-4xl text-white bg-transparent border-0 outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                ×
              </button>
            </div>
            {/*body*/}
            <div className="relative flex-auto p-5">
              <label
                htmlFor="first-name"
                className="block font-medium uppercase"
              >
                Project name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="Enter Operation Name"
                className="block w-full mt-1 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                onChange={(e) => setTitle(e.target.value)}
              />
              <label
                htmlFor="about"
                className="block mt-3 font-medium uppercase"
              >
                project description
              </label>
              <textarea
                id="about"
                name="about"
                rows={3}
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter Operation Name"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end pb-3 pr-3">
              <button
                className="p-2 mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="inline-flex justify-center p-2 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                type="button"
                disabled={
                  Title.trim() == "" || Description.trim() == "" ? true : false
                }
                onClick={() => {
                  createProjectHandler();
                  setShowModal(false);
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}

export default AddProject;
