import React from "react";

const NoResultFound = (props) => {
  const { item } = props;
  return (
    <>
      <tr>
        <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
          {item.searchName}
        </td>
        <td className="border-y border-[#919195] p-2">&nbsp;</td>
        <td className="border-y border-[#919195] p-2"> &nbsp;</td>
        <td className="border-y border-[#919195] p-2"> &nbsp;</td>
        <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
          &nbsp;
        </td>
      </tr>
      <tr>
        <td colSpan={9} className="py-1"></td>
      </tr>
    </>
  );
};

export default NoResultFound;
