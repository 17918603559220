import "../../../App.css";
import { connect } from "react-redux";
import VirtualDatasets from "./ViewVirtualDatasets";
import {
  fetchDataViewVDSList,
  deleteVirtualDataset,
} from "../../../Redux/actions";

const mapDispatchToProps = (dispatch) => ({
  fetchDataViewVDSList: () => fetchDataViewVDSList(dispatch),
  deleteVirtualDataset: (props) => deleteVirtualDataset(dispatch, props),
});

const mapStateToProps = (state) => {
  return {
    isLoading: state.DataView.isVDSDataLoading,
    vdsList: state.DataView.vdsList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualDatasets);
