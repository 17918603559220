import "../../App.css";
import { connect } from "react-redux";
import ProjectOverview from "./ProjectOverview";
import {
  FetchHomePageHeaderInfo,
  createProjectModel,
  projectAddRemoveArtifact,
  fetchModelsRefresh,
  fetchVdsesRefresh,
  fetchQueryRefresh,
  fetchWordEmbeddingsRefresh,
  fetchModelTrainingRefresh,
  fetchProjectList,
} from "../../Redux/actions";

const mapDispatchToProps = (dispatch) => ({
  FetchHomePageHeaderInfo: () => FetchHomePageHeaderInfo(dispatch),
  createProjectModel: (payload) => createProjectModel(dispatch, payload),
  projectAddRemoveArtifact: (payload) =>
    projectAddRemoveArtifact(dispatch, payload),
  fetchModelsRefresh: () => fetchModelsRefresh(dispatch),
  fetchVdsesRefresh: () => fetchVdsesRefresh(dispatch),
  fetchQueryRefresh: () => fetchQueryRefresh(dispatch),
  fetchWordEmbeddingsRefresh: () => fetchWordEmbeddingsRefresh(dispatch),
  fetchModelTrainingRefresh: () => fetchModelTrainingRefresh(dispatch),
  fetchProjectList: () => fetchProjectList(dispatch),
});

const mapStateToProps = (state) => {
  return {
    projectList: state.ListReducer.projectList,
    overViewList: state.ListReducer.overviewList,
    vdsList: state.ListReducer.vdsList,
    modelList: state.ListReducer.modelList,
    trainList: state.ListReducer.trainList,
    weList: state.ListReducer.weList,
    searchQueryList: state.ListReducer.searchQueryList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);
