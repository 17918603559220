import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FaUserAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useOidc, useOidcUser } from "@axa-fr/react-oidc";
import useDarkMode from "./useDarkMode";

const FrontSpaNavbar = () => {
  const navigate = useNavigate();
  const [colorTheme, setTheme] = useDarkMode();
  const [windowSize, setWindowSize] = useState("");
  const [ActiveTab, setActiveTab] = useState("");
  const [cartPop, setCartPop] = useState(false);

  const { logout } = useOidc();
  const { oidcUser } = useOidcUser();

  const email = oidcUser
    ? oidcUser.email && oidcUser.email.length && oidcUser.email.length > 20
      ? `${oidcUser.email.split("").splice(0, 20).join("")} ...`
      : oidcUser.email
    : "";

  const initailsArr = oidcUser ? oidcUser.name && oidcUser.name.split(" ") : "";
  let initials = "";
  if (initailsArr && initailsArr.length) {
    initailsArr.forEach((item) => {
      initials = `${initials}${item.split("").splice(0, 1).join("")}`;
    });
  }

  const env = _.replace(window.location.host, "www.", "");

  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  // if (windowSize > 768) {
  //   let navLink = document.getElementById("navLink");
  //   navLink.classList.remove("hidden");
  // }
  let userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {}, [windowSize]);

  const onLogout = () => {
    logout();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className="fixed z-20 flex flex-col items-center w-full px-3 py-2 shadow-lg dark:bg-gray-800 bg-blue-theme border-box lg:flex-row">
      <Link
        to="/"
        onClick={() => {
          let navLink = document.getElementById("navLink");
          if (window.innerWidth < 768) {
            navLink.classList.add("hidden");
          }
        }}
        className="flex items-center w-full lg:w-auto"
      >
        <img
          onClick={() => window.scroll(0, 0)}
          className="object-contain w-auto"
          src={process.env.PUBLIC_URL + "/images/header-logo.png"}
          alt=""
        />
        <span className="mx-5 mt-1 font-extrabold text-white uppercase font-GreycliffCF whitespace-nowrap">
          Project ML
        </span>
      </Link>
      <div className="flex items-center justify-center w-full grow lg:w-auto lg:flex-row lg:mt-0 lg:w-11/12">
        <ul
          id="navLink"
          className="flex-col items-center order-2 hidden w-full mb-0 text-black lg:flex dark:text-white lg:order-1 lg:mt-0 lg:flex-row"
        >
          <li className="w-full my-2 border-b lg:border-none lg:w-auto lg:my-0">
            <a
              // href="#"
              className={`py-1 text-white uppercase tracking-wide text-sm font-normal hover:border-b-4 hover:border-red-theme mx-3
              ${
                ActiveTab == ""
                  ? "font-bold lg:border-b-4 lg:border-red-theme"
                  : ""
              }
                  `}
              to=""
              onClick={() => {
                navigate("/");
                setActiveTab("");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
            >
              Dashboard
            </a>
          </li>
          <span className="relative left-0 inline-block group">
            <a
              // href="#"
              className={`py-1 text-white uppercase tracking-wide text-sm font-normal hover:border-b-4 hover:border-red-theme mx-3
            ${
              ActiveTab == "data"
                ? "font-bold lg:border-b-4 lg:border-red-theme"
                : ""
            }
                `}
              to=""
              onClick={() => {
                setActiveTab("");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
            >
              Data
            </a>
            <div className="absolute hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
              <ul className="mt-2.5 pb-2 w-[13rem] rounded-b-lg bg-white border border-t-0 boder-2 border-gray-300 px-1">
                <li
                  onClick={() => {
                    setActiveTab("data");
                    navigate("/data/viewDataModel");
                  }}
                >
                  <a
                    className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                    href={"javascript:void(0)"}
                  >
                    Data Model
                  </a>
                </li>{" "}
                <li>
                  <a
                    className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                    href={"javascript:void(0)"}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("data");
                      navigate("/data/virtualdatasource");
                    }}
                  >
                    Data Source
                  </a>
                </li>
                <li>
                  <a
                    className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                    href={"javascript:void(0)"}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("data");
                      navigate("/data/virtualdatasets");
                    }}
                  >
                    Virtual Datasets
                  </a>
                </li>{" "}
                <li
                  onClick={() => {
                    setActiveTab("data");
                    navigate("/data/viewWordEmbedding");
                  }}
                >
                  <a
                    className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                    href={"javascript:void(0)"}
                  >
                    Word Embeddings
                  </a>
                </li>
              </ul>
            </div>
          </span>
          <li className="w-full my-2 border-b lg:border-none lg:w-auto lg:my-0">
            <a
              href="#"
              className={`py-1 text-white uppercase tracking-wide text-sm font-normal hover:border-b-4 hover:border-red-theme mx-3
              ${
                ActiveTab == "data ingestion"
                  ? "font-bold border-b-4 border-red-theme"
                  : ""
              }
                  `}
              to=""
              onClick={() => {
                navigate("/");
                setActiveTab("");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
            >
              data ingestion
            </a>
          </li>
          <li className="w-full my-2 border-b lg:border-none lg:w-auto lg:my-0">
            <a
              href="#"
              className={`py-1 text-white uppercase tracking-wide text-sm font-normal hover:border-b-4 hover:border-red-theme mx-3
              ${
                ActiveTab == "notebookS"
                  ? "font-bold border-b-4 border-red-theme"
                  : ""
              }
                  `}
              to=""
              onClick={() => {
                navigate("/");
                setActiveTab("");
                let navLink = document.getElementById("navLink");
                if (window.innerWidth < 768) {
                  navLink.classList.add("hidden");
                }
              }}
            >
              notebookS
            </a>
          </li>
          {/* {userData && userData.user_type_id === 0 && (
            <li className="w-full my-4 border-b lg:w-auto lg:my-0 lg:border-none xl:border-none">
              <p
                onClick={() => {
                  navigate("/clientDashboard");
                  setActiveTab("Dashboard");
                  let navLink = document.getElementById("navLink");
                  if (window.innerWidth < 768) {
                    navLink.classList.add("hidden");
                  }
                }}
                className={`px-3 py-1 rounded-full tracking-wide font-normal text-sm hover:text-yellow-500 lg:hover:bg-yellow-500 lg:hover:text-white lg:hover:bg-yellow-500 lg:hover:text-white
                ${ActiveTab == "Dashboard" ? "bg-yellow-500" : ""}
                   `}
              >
                Dashboard
              </p>
            </li>
          )}
          {userData && userData.user_type_id === 1 && (
            <li className="w-full my-4 border-b lg:w-auto lg:my-0 lg:border-none xl:border-none">
              <p
                onClick={() => {
                  navigate("/PartnerDashboard");
                  setActiveTab("Dashboard");
                  let navLink = document.getElementById("navLink");
                  if (window.innerWidth < 768) {
                    navLink.classList.add("hidden");
                  }
                }}
                className={`px-3 py-1 rounded-full tracking-wide font-normal text-sm hover:text-yellow-500 lg:hover:bg-yellow-500 lg:hover:text-white lg:hover:bg-yellow-500 lg:hover:text-white
                ${ActiveTab == "Dashboard" ? "bg-yellow-500" : ""}
                   `}
              >
                Dashboard
              </p>
            </li>
          )} */}

          <li className="flex items-center justify-center ml-auto lg:w-auto">
            <a href="#" rel="noopener noreferrer" className="mr-2">
              <img
                className="object-contain w-auto"
                src={process.env.PUBLIC_URL + "/images/help.svg"}
                alt=""
              />
            </a>
            <span className="relative inline-block group">
              <button className="inline-flex items-center justify-center font-semibold text-white uppercase rounded rounded-full w-7 h-7 bg-brightblue-theme">
                {initials}
              </button>
              <div className="absolute hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                <ul className="mt-2.5 pb-2 w-[13rem] rounded-b-lg bg-white border border-t-0 boder-2 border-gray-300 px-1">
                  <li className="flex items-center py-1 pl-3 pr-2 border-b-2 border-slate-400">
                    <span className="w-[1.75rem] h-7 flex items-center justify-center bg-brightblue-theme text-white rounded-full uppercase">
                      {initials}
                    </span>
                    <div className="flex flex-col ml-2">
                      <span className="-mb-1 text-black break-words whitespace-normal line-clamp-1">
                        {oidcUser?.name}
                      </span>
                      <span className="break-words whitespace-normal text-zinc-400 text line-clamp-1">
                        {email}
                      </span>
                    </div>
                  </li>
                  <li>
                    <a
                      className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                      href={"javascript:void(0)"}
                    >
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="flex px-4 py-2 text-black pl-11 hover:text-brightblue-theme"
                      href={"javascript:void(0)"}
                      onClick={() => onLogout()}
                    >
                      <img
                        className="mr-1"
                        src={process.env.PUBLIC_URL + "/images/logout.svg"}
                        alt=""
                      />
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </span>
            <a href="#" rel="noopener noreferrer" className="mx-2">
              <img
                className="object-contain w-auto"
                src={process.env.PUBLIC_URL + "/images/prod.svg"}
                alt=""
              />
            </a>
            <span className="mr-1 text-white lowercase">{env}</span>
          </li>
        </ul>
        <div
          onClick={() => setTheme(colorTheme)}
          className={`border-1 border-black p-2 ml-auto order-1 cursor-pointer rounded-full absolute right-2 top-1 lg:order-2 lg:relative lg:top-0 lg:right-0 lg:order-2 lg:relative lg:top-0 lg:right-0 ${
            colorTheme === "light" ? "bg-white" : "bg-gray-800"
          }`}
        >
          <HiOutlineLightBulb
            className={`${
              colorTheme === "light" ? "text-black" : "text-white"
            } `}
          />
        </div>
        {userData && (
          <div className="absolute flex text-black dark:text-white right-20 top-4 lg:hidden">
            <div className="flex items-center px-1">
              <div className="flex items-center justify-center w-8 h-8 mr-1 overflow-hidden bg-white rounded-full shadow">
                {userData.profile_image ? (
                  <img
                    className="object-contain w-full"
                    alt=""
                    src={`http://laravelcms.devdigdev.com/storage/profile_image/${userData.profilePic}`}
                  />
                ) : (
                  <FaUserAlt className="dark:text-gray-700" />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="absolute flex text-white sm:flex right-12 top-2 lg:hidden">
          {" "}
          <GiHamburgerMenu
            className="cursor-pointer"
            size={26}
            onClick={() => {
              let navLink = document.getElementById("navLink");
              navLink.classList.toggle("hidden");
              navLink.classList.toggle("flex");
            }}
          />
        </div>
      </div>
      {cartPop && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-70 ">
          <div className="px-10 py-8 bg-white rounded dark:bg-black">
            <h1 className="font-semibold dark:text-white">
              No items in cart Go to the product page
            </h1>
            <div className="flex justify-end w-full h-auto mt-10">
              <button
                onClick={() => {
                  setCartPop(!cartPop);
                  navigate("./products");
                }}
                className="w-20 h-8 text-sm text-white bg-yellow-500 rounded hover:bg-yellow-400"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FrontSpaNavbar;
