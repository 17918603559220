import "../../App.css";
import { connect } from "react-redux";
import Home from "./Home";
// import { ProjectReducer } from "../../Redux/reducers";
import { FetchHomePageHeaderInfo } from "../../Redux/actions";

const mapDispatchToProps = (dispatch) => ({
  FetchHomePageHeaderInfo: () => FetchHomePageHeaderInfo(dispatch),
});

const mapStateToProps = (state) => {
  return {
    isProjectLoading: state.ListReducer.isProjectLoading,
    sourceList: state.ListReducer.sourceList,
    completeProjectList: state.ListReducer.projectList,
    vdsList: state.ListReducer.vdsList,
    modelList: state.ListReducer.modelList,
    trainList: state.ListReducer.trainList,
    weList: state.ListReducer.weList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
