import {
  STORE_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  PROJECTS_READ,
  PROJECT_LIST_INIT,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_ERROR,
  VDS_LIST_INIT,
  VDS_LIST_SUCCESS,
  VDS_LIST_ERROR,
  TRAIN_LIST_INIT,
  TRAIN_LIST_SUCCESS,
  TRAIN_LIST_ERROR,
  MODEL_LIST_INIT,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_ERROR,
  SOURCE_LIST_INIT,
  SOURCE_LIST_SUCCESS,
  SOURCE_LIST_ERROR,
  WE_LIST_INIT,
  WE_LIST_SUCCESS,
  WE_LIST_ERROR,
  SEARCH_LIST_INIT,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_ERROR,
} from "./../actionTypes";
import { api } from "../../services/common";

export const fetchProjectList = async (dispatch) => {
  dispatch({ type: PROJECT_LIST_INIT });

  await api("/lucd/project/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: PROJECT_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: PROJECT_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: PROJECT_LIST_ERROR, payload: err }));
};

export const fetchVDSList = async (dispatch) => {
  dispatch({ type: VDS_LIST_INIT });

  await api("/lucd/vds/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: VDS_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: VDS_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: VDS_LIST_ERROR, payload: err }));
};

export const fetchTrainList = async (dispatch) => {
  dispatch({ type: TRAIN_LIST_INIT });

  await api("/lucd/train/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: TRAIN_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: TRAIN_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: TRAIN_LIST_ERROR, payload: err }));
};

export const fetchModelList = async (dispatch) => {
  dispatch({ type: MODEL_LIST_INIT });

  await api("/lucd/model/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: MODEL_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: MODEL_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: MODEL_LIST_ERROR, payload: err }));
};

export const fetchSourceList = async (dispatch) => {
  dispatch({ type: SOURCE_LIST_INIT });

  await api("/lucd/uds/sources", {
    method: "POST",
    body: {},
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: SOURCE_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: SOURCE_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: SOURCE_LIST_ERROR, payload: err }));
  // .then((res) => console.log("res", res))
  // .catch((err) => console.log("err", err));
};

export const fetchWordEmbeddings = async (dispatch) => {
  dispatch({ type: WE_LIST_INIT });

  await api("/lucd/asset/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => {
      const { data } = res;
      if (Object.prototype.hasOwnProperty.call(data, "statusCode")) {
        if (data.statusCode !== 200) {
          dispatch({ type: WE_LIST_ERROR, payload: "error" });
        }
      } else {
        dispatch({ type: WE_LIST_SUCCESS, payload: res.data });
      }
    })
    .catch((err) => dispatch({ type: WE_LIST_ERROR, payload: err }));
};

export const fetchSearchList = async (dispatch) => {
  dispatch({ type: SEARCH_LIST_INIT });

  await api("/lucd/search/read", {
    method: "POST",
    body: { uid: null },
  })
    .then((res) => dispatch({ type: SEARCH_LIST_SUCCESS, payload: res.data }))
    .catch((err) => dispatch({ type: SEARCH_LIST_ERROR, payload: err }));
};
