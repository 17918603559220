import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import _ from "lodash";
import Loader from "react-js-loader";
import {
  readSearchList,
  sortDateDescending,
  SortAscendingOrder,
  SortDescendingOrder,
} from "../../../helper";
import ViewSort from "../ViewSort";
import ViewSearch from "../ViewSearch";
import NoResultFound from "../NoResultFound";

export default function Sources(props) {
  const { sourceList, isLoading, fetchDataViewSourceList } = props;
  const [displayList, setDisplayList] = useState([]);
  const [closeSearch, setCloseSearch] = useState(false);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    fetchDataViewSourceList();
  }, []);

  const callSourceList = useCallback(async () => {
    const { data } = await readSearchList(sourceList, "", "lastIngest");
    setDisplayList([...data]);
  }, [sourceList]);

  useEffect(() => {
    callSourceList();
  }, [sourceList, callSourceList]);

  useEffect(() => {
    if (closeSearch) {
      setSearchList([]);
    }
  }, [closeSearch]);

  const onSelectChange = (sortVal) => {
    let tmpData = [];
    if (sortVal === "lastcreated") {
      tmpData = sortDateDescending(displayList, "lastIngest");
    }
    if (sortVal === "asc") {
      tmpData = SortAscendingOrder(displayList);
    }
    if (sortVal === "desc") {
      tmpData = SortDescendingOrder(displayList);
    }
    setDisplayList([...tmpData]);
  };

  const onClickCloseSearch = () => {
    // setSearchList([]);
    setCloseSearch(true);
  };

  const onSelectSearchItem = (item) => {
    if (_.isEqual(item.id, "no records found")) {
      const tmpObj = {
        searchName: "0 Result Found",
        description: "",
      };
      setSearchList([{ ...tmpObj }]);
    } else {
      setSearchList([item]);
    }
  };

  const finalList = searchList.length ? searchList : displayList;

  return (
    <div className="p-2 virtualdatasets">
      <div className="flex">
        <div className="flex items-center">
          <div className="font-semibold text-textgray2-theme font-GreycliffCF">
            Data
          </div>
          <div className="mx-2 text-textgray-theme"> &#10095; </div>
          <div className="font-semibold text-textgray2-theme">Sources</div>
        </div>
        <div className="ml-auto">
          <button
            className="flex p-2 ml-auto text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
            type="button"
            disabled={isLoading}
            onClick={fetchDataViewSourceList}
          >
            {!isLoading && (
              <img
                className="mr-1"
                src={process.env.PUBLIC_URL + "/images/refresh.svg"}
                alt=""
              />
            )}
            refresh
          </button>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex items-center">
          <h1 className="text-xl text-textblue-theme">Sources</h1>
        </div>
        <div className="ml-auto">
          <ViewSearch
            closeSearch={closeSearch}
            setCloseSearch={setCloseSearch}
            onSelectSearch={onSelectSearchItem}
            searchList={displayList}
            onClickCloseSearch={onClickCloseSearch}
          />
        </div>
      </div>
      <ViewSort onSelectChange={onSelectChange} />
      {isLoading ? (
        <div className="flex flex-wrap justify-center min-w-full min-h-[60vh] mt-5 mb-3 align-middle">
          <Loader
            type="spinner-circle"
            bgColor={"gray"}
            // title={"spinner-circle"}
            // color="black"
            size={80}
          />
        </div>
      ) : (
        <>
          <div className="bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]">
            <table className="min-w-full text-left border-separate border-spacing-0">
              <thead className="font-bold uppercase text-textblue font-GreycliffCF">
                <tr>
                  <th scope="col" className="p-2">
                    name
                  </th>
                  <th scope="col" className="p-2">
                    Size
                  </th>
                  <th scope="col" className="p-2">
                    Records
                  </th>
                  <th scope="col" className="p-2">
                    # of models
                  </th>
                  <th scope="col" className="p-2">
                    Ingest date
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalList &&
                  finalList.length > 0 &&
                  finalList.map((item) => (
                    <>
                      {_.isEqual(item.searchName, "0 Result Found") ? (
                        <NoResultFound item={item} />
                      ) : (
                        <>
                          <tr>
                            <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                              {item.searchName}
                            </td>
                            <td className="border-y border-[#919195] p-2">
                              {item.bytes} B
                            </td>
                            <td className="border-y border-[#919195] p-2">
                              {item.records}
                            </td>
                            <td className="border-y border-[#919195] p-2">-</td>
                            <td className="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                              {moment(item.lastIngest).format("ll")}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={9} className="py-1"></td>
                          </tr>{" "}
                        </>
                      )}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
