import React from "react";

function ProjectSort({ SortBy, setSortBy }) {
  return (
    <div className="flex items-center">
      Sort by:
      <select
        onChange={(e) => setSortBy(e.target.value)}
        value={SortBy}
        className="p-2 ml-2 bg-white border-2 border-zinc-400"
      >
        <option value="lastcreated">Created By</option>
        <option value="asc">Ascending Order</option>
        <option value="desc">Descending Order</option>
      </select>
    </div>
  );
}

export default ProjectSort;
