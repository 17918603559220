import React, { useState, useEffect, useCallback } from "react";
import "tw-elements";
import _ from "lodash";
import Loader from "react-js-loader";
import { ListWrapper } from "reactjs-component-library";
import {
  readSearchList,
  sortDateDescending,
  SortAscendingOrder,
  SortDescendingOrder,
} from "../../../helper";
import ViewSort from "../ViewSort";
import ViewSearch from "../ViewSearch";
import NoResultFound from "../NoResultFound";
import VirtualDatasetRow from "./VirtualDatasetRow";

function ViewVirtualDatasets(props) {
  const { vdsList, fetchDataViewVDSList, isLoading, deleteVirtualDataset } =
    props;
  const [displayList, setDisplayList] = useState([]);
  const [closeSearch, setCloseSearch] = useState(false);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    fetchDataViewVDSList();
  }, []);

  const callVDSList = useCallback(async () => {
    const { data } = await readSearchList(vdsList);
    setDisplayList([...data]);
  }, [vdsList]);

  useEffect(() => {
    callVDSList();
  }, [vdsList, callVDSList]);

  useEffect(() => {
    if (closeSearch) {
      setSearchList([]);
    }
  }, [closeSearch]);

  const onSelectChange = (sortVal) => {
    let tmpData = [];
    if (sortVal === "lastcreated") {
      tmpData = sortDateDescending(displayList);
    }
    if (sortVal === "asc") {
      tmpData = SortAscendingOrder(displayList);
    }
    if (sortVal === "desc") {
      tmpData = SortDescendingOrder(displayList);
    }
    setDisplayList([...tmpData]);
  };

  const onClickCloseSearch = () => {
    // setSearchList([]);
    setCloseSearch(true);
  };

  const onSelectSearchItem = (item) => {
    if (_.isEqual(item.id, "no records found")) {
      const tmpObj = {
        searchName: "0 Result Found",
        description: "",
      };
      setSearchList([{ ...tmpObj }]);
    } else {
      setSearchList([item]);
    }
  };

  const onDeleteSuccess = () => {
    fetchDataViewVDSList();
  };

  const finalList = searchList.length ? searchList : displayList;

  return (
    <div className="p-2 virtualdatasets">
      <div className="flex">
        <div className="flex items-center">
          <div className="font-semibold text-textgray2-theme font-GreycliffCF">
            Data
          </div>
          <div className="mx-2 text-textgray-theme"> &#10095; </div>
          <div className="font-semibold text-textgray2-theme">
            Virtual Datasets
          </div>
        </div>
        <div className="ml-auto">
          <button
            className="flex p-2 ml-auto text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
            type="button"
            disabled={isLoading}
            onClick={fetchDataViewVDSList}
          >
            {!isLoading && (
              <img
                className="mr-1"
                src={process.env.PUBLIC_URL + "/images/refresh.svg"}
                alt=""
              />
            )}
            refresh
          </button>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex items-center">
          <h1 className="text-xl text-textblue-theme">Virtual Datasets</h1>
        </div>
        <div className="ml-auto">
          <ViewSearch
            closeSearch={closeSearch}
            setCloseSearch={setCloseSearch}
            onSelectSearch={onSelectSearchItem}
            searchList={displayList}
            onClickCloseSearch={onClickCloseSearch}
          />
        </div>
      </div>
      <ViewSort onSelectChange={onSelectChange} />
      {isLoading ? (
        <div className="flex flex-wrap justify-center min-w-full min-h-[60vh] mt-5 mb-3 align-middle">
          <Loader
            type="spinner-circle"
            bgColor={"gray"}
            // title={"spinner-circle"}
            // color="black"
            size={80}
          />
        </div>
      ) : (
        <ListWrapper>
          <div className="bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]">
            <div className="flex px-3 py-3 font-bold uppercase text-textblue font-GreycliffCF">
              <div className="w-1/3">name</div>
              <div className="w-1/3 -ml-2">description</div>
              <div className="w-1/3 -ml-1">operations</div>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ maxHeight: "70vh", overflowY: "scroll" }}
            >
              {finalList &&
                finalList.length > 0 &&
                finalList.map((item, index) => (
                  <>
                    {_.isEqual(item.searchName, "0 Result Found") ? (
                      <>
                        <table className="min-w-full text-left border-separate border-spacing-0">
                          <tbody>
                            <NoResultFound item={item} />
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <VirtualDatasetRow
                        item={item}
                        index={index}
                        deleteVirtualDataset={deleteVirtualDataset}
                        displayList={displayList}
                        onDeleteSuccess={onDeleteSuccess}
                      />
                    )}
                  </>
                ))}
            </div>
          </div>
        </ListWrapper>
      )}
      {/* <DeleteModal showModal={showModal} setShowModal={setShowModal} /> */}
    </div>
  );
}

export default ViewVirtualDatasets;
