import React from "react";
import { useState } from "react";

export default function Project() {
  const [showModal, setShowModal] = useState(false);
  const workflows = () => {
    return (
      <>
        <img
          className=""
          src={process.env.PUBLIC_URL + "/images/workflows.png"}
          alt=""
        />
        <div className="flex">
          <button
            className="inline-flex justify-center p-2 ml-auto mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
            type="button"
          >
            CREATE NEW WORKFLOW
          </button>
        </div>
      </>
    );
  };
  const queries = () => {
    return (
      <>
        <div className="flex">
          <button
            className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
            type="button"
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
        <div class="overflow-x-auto">
          <table class="min-w-full border-separate border-spacing-0 text-left">
            <thead class="text-xs uppercase">
              <tr>
                <th scope="col" class="p-2">
                  name
                </th>
                <th scope="col" class="p-2">
                  Last modified
                </th>
                <th scope="col" class="p-2">
                  description
                </th>
                <th scope="col" class="p-2">
                  Federation
                </th>
                <th scope="col" class="p-2">
                  virtual datasets
                </th>
                <th scope="col" class="p-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the workflow ...
                </td>
                <td class="border-y border-[#919195] p-2">11/12/2022</td>
                <td class="border-y border-[#919195] p-2">
                  Description of workflow h...
                </td>
                <td class="border-y border-[#919195] p-2">prod.askboss.ai</td>
                <td class="border-y border-[#919195] whitespace-nowrap p-2">
                  2
                </td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme"
                    type="button"
                  >
                    Transform
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-3">
          <button
            className="inline-flex justify-center p-2 ml-auto mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
            type="button"
          >
            CREATE NEW QUERY
          </button>
        </div>
      </>
    );
  };
  const virtualdatasets = () => {
    return (
      <>
        <div className="flex">
          <button
            className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
            type="button"
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
        <table class="min-w-full border-separate border-spacing-0 text-left">
          <thead class="text-xs uppercase">
            <tr>
              <th scope="col" class="p-2">
                name
              </th>
              <th scope="col" class="p-2">
                description
              </th>
              <th scope="col" class="p-2">
                operations
              </th>
              <th scope="col" class="p-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow goes h...
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow here and h...
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                {/* <span class="group relative inline-block">
                          <a class="inline-flex items-center justify-center">
                            <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                            <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                            <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                          </a>
                          <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                            <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">                                
                              <li><a class="flex py-2 px-4 text-black hover:text-brightblue-theme" href="#">Train</a></li>                                
                            </ul>
                          </div>
                        </span> */}
                <div id="drop_down_with_links">
                  <button id="trigger">dev</button>
                  <ul id="menu" class="closed">
                    <li>
                      <a href="#" class="link">
                        Dev
                      </a>
                    </li>
                    <li>
                      <a href="#" class="link">
                        Dev
                      </a>
                    </li>
                    <li>
                      <a href="#" class="link">
                        Dev
                      </a>
                    </li>
                    <li>
                      <a href="#" class="link">
                        Dev
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className="py-1"></td>
            </tr>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow goes h...
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                <span class="group relative inline-block">
                  <a class="inline-flex items-center justify-center">
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                  </a>
                  <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                    <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">
                      <li>
                        <a
                          class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                          href="#"
                        >
                          Train
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className="py-1"></td>
            </tr>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                <span class="group relative inline-block">
                  <a class="inline-flex items-center justify-center">
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                  </a>
                  <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                    <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">
                      <li>
                        <a
                          class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                          href="#"
                        >
                          Train
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className="py-1"></td>
            </tr>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                <span class="group relative inline-block">
                  <a class="inline-flex items-center justify-center">
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                  </a>
                  <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                    <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">
                      <li>
                        <a
                          class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                          href="#"
                        >
                          Train
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className="py-1"></td>
            </tr>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                <span class="group relative inline-block">
                  <a class="inline-flex items-center justify-center">
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                  </a>
                  <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                    <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">
                      <li>
                        <a
                          class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                          href="#"
                        >
                          Train
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={9} className="py-1"></td>
            </tr>
            <tr>
              <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                Name of the workflow
              </td>
              <td class="border-y border-[#919195] p-2">
                Description of workflow
              </td>
              <td class="border-y border-[#919195] p-2">1</td>
              <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                <span class="group relative inline-block">
                  <a class="inline-flex items-center justify-center">
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                    <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                  </a>
                  <div className="absolute z-10 hidden translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0 group-hover:block">
                    <ul class="mt-0 pb-2 w-40 bg-white shadow px-1">
                      <li>
                        <a
                          class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                          href="#"
                        >
                          Train
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  const models = () => {
    return (
      <>
        <div className="flex">
          <button
            className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
            type="button"
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
        <div class="overflow-x-auto">
          <table class="min-w-full border-separate border-spacing-0 text-left">
            <thead class="text-xs uppercase">
              <tr>
                <th scope="col" class="p-2">
                  name
                </th>
                <th scope="col" class="p-2">
                  type
                </th>
                <th scope="col" class="p-2">
                  author
                </th>
                <th scope="col" class="p-2">
                  Library
                </th>
                <th scope="col" class="p-2">
                  framework
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model{" "}
                </td>
                <td class="border-y border-[#919195] p-2">Regression</td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2">SK</td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model goes here he..
                </td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2">John Doe</td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model
                </td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model
                </td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model
                </td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
              <tr>
                <td colSpan={9} className="py-1"></td>
              </tr>
              <tr>
                <td class="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                  Name of the Model
                </td>
                <td class="border-y border-[#919195] p-2">Tabular_clas...</td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-y border-[#919195] p-2"></td>
                <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2">
                  S
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-3">
          <button
            type="submit"
            className="inline-flex justify-center p-2 ml-auto text-sm font-medium text-white uppercase border border-transparent rounded shadow-sm bg-brightblue-theme hover:bg-indigo-700 focus:outline-none"
            onClick={() => setShowModal(true)}
          >
            UPLOAD NEW MODEL
          </button>
          {showModal ? (
            <>
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto min-w-[75%] md:min-w-[50%] xl:min-w-[25%]">
                  {/*content*/}
                  <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between bg-brightblue-theme border-b border-solid border-slate-200 rounded-t-[20px] text-white">
                      <h3 className="py-3 pl-3 m-0 font-semibold text-white">
                        Upload Modal
                      </h3>
                      <button
                        className="ml-auto mr-3 text-4xl text-white bg-transparent border-0 outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        ×
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative flex-auto p-5">
                      <div className="flex flex-col mb-3">
                        <button
                          type="button"
                          className="inline-flex justify-center p-2 mr-auto text-sm font-medium text-white uppercase border border-transparent rounded shadow-sm bg-green-theme hover:bg-indigo-700 focus:outline-none"
                        >
                          Select file
                        </button>
                        <small className="mt-1 text-slate-600">
                          py file only. Max File Size 5MB
                        </small>
                      </div>
                      <label
                        htmlFor="first-name"
                        className="block font-medium uppercase"
                      >
                        Project name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        placeholder="Enter Operation Name"
                        className="block w-full p-2 mt-1 border-2 rounded-md border-zinc-400"
                      />
                      <label
                        htmlFor="about"
                        className="block mt-3 font-medium uppercase"
                      >
                        project description
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full p-2 mt-1 border-2 rounded-md border-zinc-400"
                        placeholder="Enter Operation Name"
                      />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end pb-3 pr-3">
                      <button
                        className="p-2 mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="inline-flex justify-center p-2 mr-1 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
            </>
          ) : null}
        </div>
      </>
    );
  };
  const data = [
    {
      label: "workflows",
      link: "#",
      value: "54",
      desc: workflows(),
    },
    {
      label: "queries",
      link: "#",
      value: "54",
      desc: queries(),
    },

    {
      label: "virtual datasets",
      link: "#",
      value: "8",
      desc: virtualdatasets(),
    },

    {
      label: "models",
      link: "#",
      value: "8",
      desc: models(),
    },

    {
      label: "nlp data",
      link: "#",
      value: "23",
      desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
    },

    {
      label: "model trainings",
      link: "#",
      value: "5",
      desc: `Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.Dev We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams.`,
    },
  ];
  const [openTab, setOpenTab] = useState("workflows");
  return (
    <div className="p-5">
      <div className="flex flex-wrap">
        <div className="w-full md:w-full lg:w-9/12 xl:w-8/12 2xl:w-8/12">
          <div className="pl-2 rounded-lg shadow-md bg-brightblue-theme sm:h-72">
            <div className="flex flex-wrap -mr-1 bg-white rounded-r-lg">
              <div className="w-full sm:w-1/3 rounded-r-lg group relative hover:after:content-[''] hover:after:inset-0 after:hover:bg-slate-50/[0.6] hover:after:z-0 hover:after:w-full hover:after:h-full hover:after:absolute">
                <button
                  className="absolute z-10 hidden w-32 h-10 p-2 text-sm uppercase transition-all duration-150 ease-linear -translate-x-1/2 -translate-y-1/2 border-2 rounded background-transparent inset-1/2 border-brightblue-theme text-brightblue-theme group-hover:inline-block"
                  type="button"
                >
                  change COVER
                </button>
                {/* <img src={process.env.PUBLIC_URL + "/images/img1.png"} alt="" className="w-full aspect-[302/273] object-cover object-center h-72" /> */}
                <img
                  src="https://images.unsplash.com/photo-1674570995544-4e6260871f88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                  alt=""
                  className="w-full aspect-[302/273] object-cover object-center h-48 sm:h-72"
                />
              </div>
              <div className="flex flex-col w-full py-3 pl-2 pr-2 sm:w-2/3 sm:pl-5">
                <div className="flex flex-wrap items-center justify-end gap-2 pb-3">
                  <button
                    className="inline-flex justify-center p-2 text-sm font-medium text-white uppercase transition-all duration-150 ease-linear border border-transparent rounded shadow-sm shadow outline-none bg-brightblue-theme hover:bg-indigo-700 focus:outline-none active:bg-emerald-600 hover:shadow-lg"
                    type="button"
                  >
                    Open Project
                  </button>
                  <div className="relative">
                    <button
                      className="px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
                      type="button"
                    >
                      COPY Project ID
                    </button>
                    <div className="absolute left-0 flex p-2 bg-white shadow-md">
                      <img
                        src={process.env.PUBLIC_URL + "/images/tick.svg"}
                        alt=""
                        className="mr-1"
                      />
                      Project ID copied
                    </div>
                  </div>
                  <button
                    className="relative p-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-pourpre-theme text-pourpre-theme group"
                    type="button"
                  >
                    delete
                    <span className="pointer-events-none absolute top-0 right-16 w-80 rounded bg-white text-slate-900 p-3 shadow-lg hidden transition before:absolute before:left-0 before:top-full before:border-4 before:border-transparent before:content-[''] group-hover:block text-left">
                      <div className="font-medium">
                        Are you sure you want to delete this project?
                      </div>
                      <em>This action will not delete project artifacts.</em>
                      <div className="text-right">
                        <button
                          className="p-2 mr-2 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-slate-600 text-slate-600"
                          type="button"
                        >
                          Cancel
                        </button>
                        <button
                          className="p-2 text-sm text-white uppercase transition-all duration-150 ease-linear border-2 rounded bg-pourpre-theme border-pourpre-theme"
                          type="button"
                        >
                          delete
                        </button>
                      </div>
                    </span>
                  </button>
                </div>
                <h2 className="mb-2 text-sm font-medium leading-8 uppercase line-clamp-1 group">
                  Monterey Bay Course
                  <a href="" className="hidden group-hover:inline-block">
                    <img
                      src={process.env.PUBLIC_URL + "/images/edit.png"}
                      alt=""
                      className="ml-1"
                    />
                  </a>
                </h2>
                <p className="text-sm font-medium leading-8 text-zinc-400 group">
                  Monterey Bay Course text description goes here and here and
                  here however long this is to here Monterey Bay Course
                  textMonterey Bay Course text.
                  <a
                    href=""
                    className="relative hidden group-hover:inline-block group2"
                  >
                    <span className="pointer-events-none absolute -top-10 left-1/6 -translate-x-1/6 whitespace-nowrap rounded bg-blue-theme px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/6 before:top-full before:-translate-x-1/6 before:border-4 before:border-transparent before:content-[''] group2-hover:opacity-100">
                      Edit project description
                    </span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/edit.png"}
                      alt=""
                      className="ml-1"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* <img
            src={process.env.PUBLIC_URL + "/images/projectpage-version2.png"}
            alt=""
            className="mx-auto mt-5"
          /> */}
          <div className="flex flex-col mt-5 rounded shadow">
            <ul className="flex m-0 overflow-hidden border rounded-t-lg border-blue-theme">
              {data.map(({ label, link, value }) => (
                <li
                  key={label}
                  className={`border-l border-blue-theme first:border-0 first:w-52 first:grow-0 grow text-center uppercase ${
                    label === openTab ? "bg-blue-theme" : " bg-gray-theme"
                  } hover:bg-brightblue-theme`}
                >
                  <a
                    href={link}
                    onClick={() => setOpenTab(label)}
                    className={`p-4 block text-center ${
                      label === openTab ? "text-white" : " text-blue-theme"
                    } hover:text-white flex justify-center flex-col h-full`}
                  >
                    <div className="text-base">{label}</div>
                    <div className="text-4xl">{value}</div>
                  </a>
                </li>
              ))}
            </ul>
            <div className="flex">
              <div className="bg-gray-theme px-4 pb-4 pt-12 w-52 md:min-w-[13rem] flex justify-center relative">
                <div className="absolute -mt-8 text-center uppercase text-secondblue-theme">
                  Federated
                </div>
                <div class="flex flex-col mr-5 -ml-12">
                  <div class="text-green-theme mb-auto font-semibold">80%</div>
                  <div class="text-red2-theme mt-auto font-semibold">20%</div>
                </div>
                <div class="h-full bg-red2-theme w-9">
                  <div
                    class="bg-green-theme w-9"
                    style={{ height: "80%" }}
                  ></div>
                </div>
              </div>
              {data.map(({ label, desc }) => (
                <div
                  key={label}
                  className={`bg-white p-4 grow ${
                    label === openTab ? "block" : "hidden"
                  }`}
                >
                  <h2 className="text-lg uppercase">{label}</h2>
                  {desc}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full pl-0 mt-4 md:w-full lg:w-3/12 xl:w-4/12 2xl:w-4/12 lg:pl-5 lg:mt-0">
          <div className="p-3 bg-white rounded-lg shadow-lg">
            <h2 className="pb-2 mb-1 text-xl border-b border-gray-400 text-textblue-theme">
              Your Recent Activity
            </h2>
            <div className="pr-1 overflow-y-auto lg:h-screen">
              <p className="uppercase text-secondblue-theme">TODAY</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>{" "}
                    <span className="ml-1 font-semibold text-pourpre-theme">
                      Monterey Bay Course
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Operation created:</span>{" "}
                    <span className="ml-1 font-semibold">
                      Select Military crashes...
                    </span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold">Project:</span>{" "}
                <span className="ml-1 font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
              <p className="uppercase text-secondblue-theme">March 31, 2022</p>
              <div className="p-2 mb-1 bg-gray-theme text-textblue-theme h-min">
                <div className="flex items-start">
                  <img
                    className="mr-1"
                    src={process.env.PUBLIC_URL + "/images/round-plus.svg"}
                    alt=""
                  />
                  <div className="h-6 line-clamp-1">
                    <span>New Project created:</span>
                  </div>
                  <span className="ml-auto whitespace-nowrap">12:18 PM</span>
                </div>
                <span className="font-semibold text-pourpre-theme">
                  Monterey Bay Course
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
