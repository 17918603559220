import React, { useEffect, useState, useCallback } from "react";
import "tw-elements";
import Loader from "react-js-loader";
import { api } from "./../../services/common";
import ProjectSort from "../Home/ProjectSort";
import {
  sortDateDescending,
  SortModalDataAscendingOrder,
  SortModalDataDescendingOrder,
} from "../../helper";

const ViewDataModel = () => {
  const [ModalData, setModalData] = useState([]);
  const [IsLloading, setIsLloading] = useState(false);
  const [SortBy, setSortBy] = useState("lastcreated");

  useEffect(() => {
    getModalData();
  }, []);

  useEffect(() => {
    setListData(ModalData);
  }, [SortBy]);

  const setListData = useCallback((list) => {
    setIsLloading(true);
    if (list.length) {
      const data = list;
      let tmpData;
      if (SortBy === "lastcreated") {
        tmpData = sortDateDescending(data);
      }
      if (SortBy === "desc") {
        tmpData = SortModalDataDescendingOrder(data);
      }
      if (SortBy === "asc") {
        tmpData = SortModalDataAscendingOrder(data);
      }
      setModalData(tmpData);
      setIsLloading(false);
    }
  }, []);

  const getModalData = async () => {
    setIsLloading(true);
    await api("/lucd/model/read", {
      method: "POST",
      body: { uid: null },
    })
      .then((res) => {
        let modals = [];
        if (res.data) {
          Object.keys(res.data).forEach((item) => {
            const id = item;
            modals.push({ ...res.data[item], id });
          });
          // setModalData(modals);
          setModalData(sortDateDescending(modals));
        } else {
          setModalData([]);
        }
        setIsLloading(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="virtualdatasets p-2">
      <div className="flex">
        <div className="flex items-center">
          <a className="text-slate-900 font-semibold font-GreycliffCF" href="">
            Data
          </a>
          <div className="mx-2 text-textgray-theme"> &#10095; </div>
          <div className="font-semibold text-textgray2-theme">Models</div>
        </div>
        <div className="ml-auto">
          <button
            className="ml-auto background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 ease-linear transition-all duration-150 flex"
            type="button"
            onClick={() => {
              setModalData([]);
              getModalData();
            }}
          >
            <img
              className="mr-1"
              src={process.env.PUBLIC_URL + "/images/refresh.svg"}
              alt=""
            />{" "}
            refresh
          </button>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex items-center">
          <h1 className="text-xl text-textblue-theme">Models</h1>
        </div>
        <div className="ml-auto">
          <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white w-52">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
            <select className="flex-grow outline-none text-gray-600 ml-1 appearance-none px-2">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            <img
              className="w-4 ml-1 cursor-pointer"
              src={process.env.PUBLIC_URL + "/images/close-gray.svg"}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="flex mt-2 items-center">
        {/* Sort by :
            <div className="border-2 border-zinc-400 ml-2 py-1 px-2 flex justify-between rounde-md rounded-md bg-white">
              <select className="flex-grow outline-none text-gray-600 p-1 w-52">
                <option value="lastcreated">Created By</option>
                <option value="asc">Ascending Order</option>
                <option value="desc">Descending Order</option>
              </select>
            </div> */}
        <ProjectSort SortBy={SortBy} setSortBy={setSortBy} />
      </div>
      <div className="bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]">
        {IsLloading === true ? (
          <Loader
            type="spinner-circle"
            bgColor={"gray"}
            // title={"spinner-circle"}
            // color="black"
            size={100}
          />
        ) : (
          <>
            <div className="flex py-3 px-3 text-textblue uppercase font-GreycliffCF font-bold">
              <div className="w-1/3">name</div>
            </div>
            {console.log("ModalData", ModalData)}
            {ModalData.length
              ? ModalData.map((item, index) => {
                  return (
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                      key={item?.id}
                    >
                      <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
                        <div
                          id={item?.id}
                          class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          <div className="w-1/3">
                            {item.model_name ? item.model_name : "no name"}
                          </div>
                        </div>
                        <div
                          id={`flush-collapse${index}`}
                          class="accordion-collapse collapse"
                          aria-labelledby={item?.id}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0 bg-gray2-theme">
                            <div className="h-96 overflow-y-auto pr-1 secondary-blue-scroll">
                              <div className="flex gap-4">
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                                <div className="bg-white w-52 p-2">
                                  <div className="font-bold">Feature</div>
                                  <a href="" className="font-semibold">
                                    Aircraft_engine
                                  </a>
                                  <div className="font-bold">Type</div>
                                  <a href="" className="font-semibold">
                                    Type goes here
                                  </a>
                                </div>
                              </div>
                              <div className="mt-3">
                                <table class="min-w-full border-separate border-spacing-0 text-left">
                                  <tbody>
                                    <tr className="bg-lightblue-theme">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-white">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-lightblue-theme">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-white">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-lightblue-theme">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-white">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-lightblue-theme">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={9} className="py-1"></td>
                                    </tr>
                                    <tr className="bg-white">
                                      <td class="rounded-l-lg whitespace-nowrap p-2">
                                        BF0056G879
                                      </td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="p-2">30</td>
                                      <td class="rounded-r-lg whitespace-nowrap p-2">
                                        30
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No records found!"}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewDataModel;
