import React, { useRef, useEffect, useState } from "react";
import Loader from "react-js-loader";

const VdsesDetails = ({ VirtualDatasetsData, refreshPage }) => {
  const [TrainRecord, setTrainRecord] = useState(null);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setIsVisible(false);
      if (TrainRecord !== null) {
        setTrainRecord(null);
        setIsVisible(false);
      }
    }
  };

  const isLoading =
    VirtualDatasetsData &&
    VirtualDatasetsData.length &&
    VirtualDatasetsData[0] === "isLoading";

  if (isLoading) {
    return (
      <Loader
        type="spinner-circle"
        bgColor={"gray"}
        // title={"spinner-circle"}
        // color="black"
        size={50}
      />
    );
  }

  return (
    <>
      <div
        className="flex"
        role="button"
        onClick={() => refreshPage({ isVdses: true })}
        disabled={isRefreshDisabled}
      >
        <button
          className="flex p-2 ml-auto mr-1 text-sm uppercase transition-all duration-150 ease-linear border-2 rounded background-transparent border-brightblue-theme text-brightblue-theme"
          type="button"
        >
          <img
            className="mr-1"
            src={process.env.PUBLIC_URL + "/images/refresh.svg"}
            alt=""
          />{" "}
          refresh
        </button>
      </div>
      <div>
        <table className="min-w-full border-separate border-spacing-0 text-left">
          <thead className="text-xs uppercase">
            <tr>
              <th scope="col" className="p-2">
                Name
              </th>
              <th scope="col" className="p-2">
                Description
              </th>
              <th scope="col" className="p-2">
                Operations
              </th>
              <th scope="col" className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {VirtualDatasetsData.length > 0
              ? VirtualDatasetsData.map((item) => {
                  return (
                    <>
                      <tr>
                        <td className="border-l border-y border-[#919195] rounded-l-lg whitespace-nowrap p-2">
                          {item.name}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {item.description}
                        </td>
                        <td className="border-y border-[#919195] p-2">
                          {item.operations !== undefined
                            ? item.operations.length
                            : "0"}
                        </td>
                        <td class="border-r border-y border-[#919195] rounded-r-lg whitespace-nowrap p-2 text-right">
                          <span class="group relative inline-block">
                            {/* <button
                              type="button"
                              class="button"
                              onClick={() => {
                                setTrainRecord(item);
                                setIsVisible(true);
                              }}
                            >
                              <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                              <span className="inline-block p-0.5 mx-1 rounded-full bg-dots-theme"></span>
                              <span className="inline-block p-0.5 rounded-full bg-dots-theme"></span>
                            </button>
                            {TrainRecord?.id === item.id &&
                            isVisible == true ? (
                              <div
                                ref={wrapperRef}
                                className="z-10 absolute translate-x-2/4 sm:translate-x-0 right-1/2 sm:right-0"
                              >
                                <div className="mt-0 pb-2 w-40 bg-white shadow px-1 dropdown">
                                  <div
                                    class="flex py-2 px-4 text-black hover:text-brightblue-theme"
                                    href="javascript:void(0);"
                                  >
                                    Train
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="py-1"></td>
                      </tr>
                    </>
                  );
                })
              : "No records found!"}
          </tbody>
        </table>
      </div>
      {/* <div className="flex mt-3">
        <button
          className="ml-auto inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          CREATE NEW QUERY
        </button>
      </div> */}
    </>
  );
};

export default VdsesDetails;
