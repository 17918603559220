import React, { useState, useEffect } from "react";

const Footer = () => {
  const [FooterMessage, setFooterMessage] = useState(
    "Thank you for contacting us!"
  );
  let date = new Date();
  useEffect(() => {
    // getFooterData();
  }, []);

  return (
    <div className="flex-col items-center justify-between hidden w-full px-3 py-4 bg-gray-800 sm:flex md:flex lg:flex xl:flex md:flex-row lg:h-10 lg:flex-row">
      <div>
        <h1 className="text-sm font-normal text-white">
          © 2019 - {date.getFullYear()} {FooterMessage}
        </h1>
      </div>
      <div className="mt:2 lg:mt-0">
        <span className="text-xs text-white">Powered By </span>
        <span
          className="text-sm text-blue-400 cursor-pointer"
          onClick={() => window.open("https://www.devdigital.com/")}
        >
          DevDigital : Nashville Software Development
        </span>
      </div>
    </div>
  );
};

export default Footer;
