import { FetchInstance } from "./index";

const fetch = FetchInstance();
const BASE_URL = "https://dev.askboss.ai";

export const api = async (url, info) => {
  const { method } = info;
  let resultSet = { data: [] };
  if (fetch) {
    const fetchAPIAsync = async () => {
      if (method === "GET" && url) {
        const res = await fetch(`${BASE_URL}${url}`, {
          method: info.method,
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("res", res);
        if (res.status >= 300) {
          return { data: [], statusCode: res.status };
        }
        return res.json();
      } else {
        const res = await fetch(`${BASE_URL}${url}`, {
          method: info.method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(info.body),
        });
        // console.log("res", res);
        if (res.status >= 300) {
          return { data: [], statusCode: res.status };
        }
        return res.json();
      }
    };
    await fetchAPIAsync().then((apiData) => {
      resultSet = {
        data: apiData,
      };
    });
  }
  return resultSet;
};

export const fileUploadApi = async (url, info) => {
  const { method } = info;
  let resultSet = { data: [] };
  if (fetch) {
    const fetchAPIAsync = async () => {
      if (method === "GET" && url) {
        const res = await fetch(`${BASE_URL}${url}`, {
          method: info.method,
          headers: {},
        });
        if (res.status >= 300) {
          return { data: [], statusCode: res.status };
        }
        return res.json();
      } else {
        var form_data = new FormData();
        for (var key in info.body) {
          if (key.includes("file")) {
            form_data.append(`${key}`, info.body[key][0]);
          } else form_data.append(`${key}`, String(info.body[key]));
        }
        const res = await fetch(`${BASE_URL}${url}`, {
          method: info.method,
          body: form_data,
        });
        if (res.status >= 300) {
          return { data: [], statusCode: res.status };
        }
        return res.json();
      }
    };
    await fetchAPIAsync().then((apiData) => {
      resultSet = {
        data: apiData,
      };
    });
  }
  return resultSet;
};
