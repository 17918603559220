import React from "react";
import { useNavigate } from "react-router-dom";
import { getImagefromURL } from "reactjs-component-library";

function Project(props) {
  const navigate = useNavigate();
  const { projectList } = props;

  return (
    <>
      {projectList &&
        projectList.map((item) => {
          const name =
            item.name && item.name.length && item.name.length > 18
              ? `${item.name.split("").splice(0, 18).join("")} ...`
              : item.name;

          return (
            <>
              {item && item?.searchName === "0 Result Found" ? (
                <>0 Result Found</>
              ) : (
                <div
                  className="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/5"
                  key={item.id}
                >
                  <div className="h-48 pl-2 rounded-lg shadow-md bg-brightblue-theme">
                    <div className="flex -mr-1 bg-white rounded-r-lg">
                      <div className="w-1/3 rounded-r-lg">
                        <img
                          src={
                            item["image.binary"]
                              ? getImagefromURL(item["image.binary"])
                              : process.env.PUBLIC_URL + "/images/img1.png"
                          }
                          alt=""
                          className="w-full aspect-[87/197] object-cover object-center h-48"
                        />
                      </div>
                      <div className="flex flex-col w-2/3 px-2 py-3">
                        <h2
                          className="relative mb-2 text-sm font-medium uppercase cursor-pointer group"
                          onClick={() => {
                            navigate("/projectoverview", {
                              state: { ProjectData: item },
                            });
                          }}
                        >
                          <span className="pointer-events-none absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-blue-theme px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/6 before:top-full before:-translate-x-1/6 before:border-4 before:border-transparent before:content-[''] group-hover:opacity-100">
                            {item.name}
                          </span>
                          {name}
                        </h2>
                        <p className="text-sm font-medium line-clamp-4 text-zinc-400">
                          {item.description}
                        </p>
                        <div className="flex mt-auto uppercase text-secondblue">
                          <div className="flex items-start mr-3">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/models.png"
                              }
                              alt=""
                              className="mt-1"
                            />
                            <div className="ml-2">
                              <h2 className="-mt-1 text-2xl">
                                {item?.models?.length || 0}
                              </h2>
                              <p className="text-xs">models</p>
                            </div>
                          </div>
                          <div className="flex items-start ml-auto">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/virtual.png"
                              }
                              alt=""
                              className="mt-1"
                            />
                            <div className="ml-2">
                              <h2 className="-mt-1 text-2xl">
                                {item?.vdses?.length || 0}
                              </h2>
                              <p className="text-xs">VIRTual datasets</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
    </>
  );
}

export default Project;
