import React from 'react';
import 'tw-elements';

export default function virtualDatasets() {
  return (
    <div className='virtualdatasets p-2'>
        <div className='flex'>
            <div className='flex items-center'>
                <a className='text-slate-900 font-semibold font-GreycliffCF' href="">Data</a>
                <div className='mx-2 text-textgray-theme'> &#10095; </div>
                <div className='font-semibold text-textgray2-theme'>Virtual Datasets</div>
            </div>
            <div className='ml-auto'>
                <button className="ml-auto background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 ease-linear transition-all duration-150 flex" type="button"><img className="mr-1" src={process.env.PUBLIC_URL + "/images/refresh.svg"} alt=""/> refresh</button>
            </div>
        </div>        
        <div className='flex mt-2'>
            <div className='flex items-center'>
                <h1 className='text-xl text-textblue-theme'>Virtual Datasets</h1>
            </div>
            <div className='ml-auto'>
                <div className="border-2 border-zinc-400 py-1 px-2 flex justify-between rounde-md rounded-md bg-white w-52">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </span>
                    <select className="flex-grow outline-none text-gray-600 ml-1 appearance-none px-2">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                    </select>
                    <img className="w-4 ml-1 cursor-pointer" src={process.env.PUBLIC_URL + "/images/close-gray.svg"} alt=""/>
                </div>
            </div>
        </div>
        <div className='flex mt-2 items-center'>
            Sort by :
            <div className="border-2 border-zinc-400 ml-2 py-1 px-2 flex justify-between rounde-md rounded-md bg-white">
                <select className="flex-grow outline-none text-gray-600 p-1 w-52">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                </select>
            </div>
        </div>
        <div className='bg-white border-2 border-slate-400 p-3 mt-2 min-h-[78vh]'>
            <div className='flex py-3 px-3 text-textblue uppercase font-GreycliffCF font-bold'>
                <div className='w-1/3'>name</div>
                <div className='w-1/3 -ml-2'>description</div>
                <div className='w-1/3 -ml-1'>operations</div>
            </div>
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
                    <div id="flush-headingOne" class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <div className='w-1/3'>Pilote Engine</div>
                        <div className='w-1/3'>Description of VDS here and here, here</div>
                        <div className='w-1/3'>1</div>
                    </div>                    
                    <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0">
                            <div className='flex items-center'>
                                <div>
                                    <div className='text-sm font-bold'>Last Modified</div>
                                    <div className='text-base'>11/22/2022 - 4:05 PM</div>
                                </div>
                                <div className='flex ml-auto'>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/external.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/copy.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/trash.svg"} alt="" className="h-6" /></a>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='text-sm font-bold'>Description</div>
                                <div className='text-base'>Description test goes here Description test goes here Description test goes hereDescription test goes hereDescription test goes here Description test goe.</div>
                            </div>
                            <div className='flex flex-wrap mt-4'>
                                <div className='w-full lg:w-2/5 flex lg:border-r-2'>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Feature columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Label columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full lg:w-3/5 mt-3 lg:mt-0'>
                                    <div className='pl-3'>
                                        <h4 className='text-sm font-bold'>Data Transformation Sequence</h4>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center justify-end">
                                <button class="background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 mr-1 ease-linear transition-all duration-150" type="button">create embedding</button>
                                <button class="inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">start training</button></div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
                    <div id="flush-headingTwo" class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <div className='w-1/3'>Pilote Engine</div>
                        <div className='w-1/3'>Description of VDS here and here, here</div>
                        <div className='w-1/3'>1</div>
                    </div>                    
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0">
                            <div className='flex items-center'>
                                <div>
                                    <div className='text-sm font-bold'>Last Modified</div>
                                    <div className='text-base'>11/22/2022 - 4:05 PM</div>
                                </div>
                                <div className='flex ml-auto'>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/external.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/copy.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/trash.svg"} alt="" className="h-6" /></a>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='text-sm font-bold'>Description</div>
                                <div className='text-base'>Description test goes here Description test goes here Description test goes hereDescription test goes hereDescription test goes here Description test goe.</div>
                            </div>
                            <div className='flex flex-wrap mt-4'>
                                <div className='w-full lg:w-2/5 flex lg:border-r-2'>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Feature columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Label columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full lg:w-3/5 mt-3 lg:mt-0'>
                                    <div className='pl-3'>
                                        <h4 className='text-sm font-bold'>Data Transformation Sequence</h4>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center justify-end">
                                <button class="background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 mr-1 ease-linear transition-all duration-150" type="button">create embedding</button>
                                <button class="inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">start training</button></div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item rounded-lg overflow-hidden bg-white mb-2 overflow-hidden">
                    <div id="flush-headingThree" class="accordion-button p-3 relative flex items-center cursor-pointer w-full border border-tablestroke collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <div className='w-1/3'>Pilote Engine</div>
                        <div className='w-1/3'>Description of VDS here and here, here</div>
                        <div className='w-1/3'>1</div>
                    </div>                    
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body p-4 border border-1 border-tablestroke rounded-lg overflow-hidden border-t-0">
                            <div className='flex items-center'>
                                <div>
                                    <div className='text-sm font-bold'>Last Modified</div>
                                    <div className='text-base'>11/22/2022 - 4:05 PM</div>
                                </div>
                                <div className='flex ml-auto'>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/external.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/copy.svg"} alt="" className="h-6" /></a>
                                    <a href="" className='ml-2'><img src={process.env.PUBLIC_URL + "/images/trash.svg"} alt="" className="h-6" /></a>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='text-sm font-bold'>Description</div>
                                <div className='text-base'>Description test goes here Description test goes here Description test goes hereDescription test goes hereDescription test goes here Description test goe.</div>
                            </div>
                            <div className='flex flex-wrap mt-4'>
                                <div className='w-full lg:w-2/5 flex lg:border-r-2'>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Feature columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-1/2 pr-3 lg:pr-5'>
                                        <div className='bg-slate-100 p-2 rounded-lg border boredr-1 border-slate-300'>
                                            <h4 className='text-sm font-bold'>Label columns</h4>
                                            <div className='h-40 overflow-y-auto pr-1 secondary-blue-scroll'>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                                <div className='mb-1'>engine_prediction.age</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full lg:w-3/5 mt-3 lg:mt-0'>
                                    <div className='pl-3'>
                                        <h4 className='text-sm font-bold'>Data Transformation Sequence</h4>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                        <div class="flex items-center">
                                            <img src={process.env.PUBLIC_URL + "/images/setting-yellow.svg"} alt="" className="h-6" />
                                            <div class="text-base ml-2">Name of Operation</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center justify-end">
                                <button class="background-transparent uppercase p-2 rounded border-brightblue-theme text-brightblue-theme text-sm border-2 mr-1 ease-linear transition-all duration-150" type="button">create embedding</button>
                                <button class="inline-flex justify-center rounded border border-transparent bg-brightblue-theme p-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none uppercase active:bg-emerald-600 shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">start training</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}